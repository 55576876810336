<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FleetManagement'"
      :ActiveMenu="'TakePayment'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Take Payment"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="FleetManagement"
        breadcrumbitem3="TakePayment"
        ActiveSubMenu="FleetManagement"
        ActiveMenu="TakePayment"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <!-- begin invoice -->
      <div class="invoice">
        <!-- begin invoice-company -->
        <div class="invoice-company text-inverse f-w-600">
          <span class="pull-right hidden-print">
            <a
              href="javascript:;"
              :disabled="$wait.is('createpayment')"
              @click="createpayment(context)"
              :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5': !$wait.is('createpayment'),
            'btn btn-lg btn-grey m-b-10 p-l-5': $wait.is('createpayment')
            }"
            >
              <i
                class="fa fa-registered t-plus-1 text-white fa-fw fa-lg"
                v-show="!$wait.is('createpayment')"
              ></i>

              <i
                class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
                v-show="$wait.is('createpayment')"
              ></i>

              <span>Pay Now</span>
            </a>
            <a
              v-show="printReady"
              href="#modal-dialog"
              data-toggle="modal"
              class="btn m-r-20 btn-lg btn-white m-b-10 p-l-5"
            >
              <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Receipt
            </a>
          </span>

           <div class="spinner-border spinner-border-lg text-info" v-if="!dataloadedABC"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
        </div>
        <!-- end invoice-company -->

        <!-- begin invoice-header -->
        <div class="invoice-header">
          <div v-show="paymentCreated" class="alert alert-success fade show">
            <span class="close" data-dismiss="alert">×</span>
            <strong>Success!</strong>
            Payment Successfully.
          </div>

          <div class="row">
            <div class="col-lg-6">
              <!-- begin card -->
              <div class="card text-center">
                <div class="card-header">
                  <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" data-toggle="tab" href="#card-tab-1">Clients</a>
                    </li>
                  </ul>
                </div>
                <div class="card-block">
                  <div class="tab-content p-0 m-0">
                    <div
                      class="tab-pane fade active show"
                      id="card-tab-1"
                    >
                      <div class="invoice-to">
                        <small>
                          <select
                            class="default-selectTaxi default-select form-control"
                            data-size="5"
                            data-live-search="true"
                          >
                          <option value="0">Select Client</option>
                            <option
                              v-for="item in GETALLTAXIS"
                              :key="'T'+item.id"
                              :value="item.id"
                            >{{item.firstname}} {{item.surname}} -  {{item.id_number}} ({{item.outstanding_balance | newcurrencywithSymbol}})</option>
                          </select>
                        </small>
                        <address class="m-t-5 m-b-5">
                          <strong class="text-inverse"></strong>
                          <br />
                          <br />
                          <br />
                          <br />
                        </address>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->
            </div>

            <div class="col-lg-6">
              <div class="invoice-date">
                <small></small>
                <div class="date text-inverse m-t-25 m-l-40 p-r-40 width-200 pull-left">
                  <label class="pull-left m-l-10">Transaction Type:</label>
                  <select
                    class="form-control selectcapturedby"
                    data-size="10"
                    data-live-search="true"
                    data-parsley-group="step-1"
                    data-parsley-required="true"
                    id="selectcapturedby"
                    v-model="DEPOSITTYPE"
                  >
                    <option value="0">Select Type</option>
                    <option
                      v-bind:key="'C'+item.id"
                      v-for="(item) in DEPOSITTYPES"
                      :value="item"
                    >{{item.name}}</option>
                  </select>
                </div>

                <div class="invoice-detail">
                  <br />
                </div>
              </div>
              <div class="invoice-date">
                <small></small>
                <div class="date text-inverse m-t-10 m-l-40 p-l-20 width-200 pull-right">
                  <label class="p-t-20">Payment Date:</label>
                  <div
                    class="input-group date"
                    id="datepicker-disabled-past"
                    data-date-format="dd M yyyy"
                  >
                    <input type="text" class="form-control" placeholder="Select Date" />
                    <span class="input-group-addon">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>

                <div class="invoice-detail">
                  <br />
                </div>
              </div>
              <br />
              <!-- begin widget-list -->
              <div
                v-if="VEHICLETRANSACTIONS.length > 0"
                class="widget-list widget-list-rounded m-b-30"
              >
                <!-- begin widget-list-item -->
                <div class="widget-list-item" :key="'VT'+item._id" v-for="item in VEHICLETRANSACTIONS">
                  <div class="widget-list-content">
                    <h4
                      class="widget-list-title"
                    >Amount: {{item._transaction_amount != null ? item._transaction_amount : 0 | currencywithSymbol }} </h4>
                    <h4
                      class="widget-list-title"
                    >Bal: {{item._remaning_balance != null ? item._remaning_balance : 0 | currencywithSymbol }} </h4>
                    <p class="widget-list-desc">
                      <b>{{item._transaction_type }}</b>
                       {{item._transaction_reference != null ? item._transaction_reference : 'N/A'}}
                    </p>
                  </div>
                  <div class="widget-list-action">
                    <h4 class="widget-list-title">{{item._captured_date | formatToLongDateTime }}</h4>
                  </div>
                </div>
                <!-- end widget-list-item -->
              </div>

              
            </div>
          </div>
        </div>
        <!-- end invoice-header -->
        <!-- begin invoice-content -->
        <div class="invoice-content">
          <!-- begin invoice-price -->
          <div class="invoice-price">
            <div class="invoice-price-left">
              <div class="invoice-price-row">
                <div class="sub-price">
                  <small>ENTER AMOUNT</small>

                  <span class="text-inverse">
                    <CurrencyInput
                      id="cash"
                      v-model="TOTALINVOICEAMOUNT"
                      :currency="currency"
                      :auto-decimal-mode="false"
                      class="height-40 text-left demo__currency-input"
                    />
                  </span>
                </div>
                <div class="sub-price">
                  <i class="fa fa-plus text-muted"></i>
                </div>
                <div class="sub-price">
                  <small v-if="ISTRANSFEEPERCENTAGE">TRANSACTION FEE ({{this.TRANSFEE}}%)</small>
                  <small v-if="!ISTRANSFEEPERCENTAGE">TRANSACTION FEE ({{this.TRANSFEE | currencywithSymbol}})</small>
                  <span class="text-inverse">R{{TRANSACTIONFEE | currency}}</span>
                </div>
              </div>
            </div>
            <div class="invoice-price-right">
              <small>TOTAL</small>
              <span class="f-w-600">R{{TOTALMOUNT | currency}}</span>
            </div>
          </div>
          <!-- end invoice-price -->
        </div>
        <!-- end invoice-content -->
        <!-- begin invoice-note -->
        <div class="invoice-note">
          <div class="form-group row">
            <label class="col-md-1 col-form-label">Reference</label>
            <div class="col-md-5">
              <textarea
                v-model="COMMENT"
                class="form-control m-b-10"
                id="clipboard-textarea"
                rows="7"
              ></textarea>
            </div>
            <div class="col-md-5">
              <DropZoneControl
                          :acceptedFiles="acceptedFiles"
                          :ClearData="true"
                          v-on:fileadded="fileadded"
                          :SessionID="DropZoneSessionID"
                        ></DropZoneControl>
            </div>
          </div>
          * Make all invoices payable to {{USERPROFILE.taxi_associations_name}}
          <br />
        </div>
        <!-- end invoice-note -->
        <!-- begin invoice-footer -->
        <div class="invoice-footer">
          <p class="text-center m-b-5 f-w-600">{{USERPROFILE.taxi_associations_name}}</p>
          <p class="text-center">
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-globe"></i>
              {{SELECTEDTAXIASSOCIATION._abbriviated_name}}.TAMS.co.za
            </span>
            <span class="m-r-10">
              <i class="fa fa-fw fa-lg fa-phone-volume"></i>
              T:{{SELECTEDTAXIASSOCIATION._telephone}}
            </span>
          </p>
        </div>
        <!-- end invoice-footer -->
      </div>
      <!-- end invoice -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

   
    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import Inputmask from "inputmask";
import { setTimeout } from "timers";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fleetmanagement");

export default {
  name: "TakePayment",
  data: function () {
    return {
      acceptedFiles: "application/pdf",
      currencySymbol: "",
      DropZoneSessionID: this.$uuid.v4(),
      position: "prefix",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      SELECTEDTAXI: {},
      SELECTEDOPERATOR: {},
      SELECTEDDRIVER: {},
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      TRANSFEE: 0,
      ISTRANSFEEPERCENTAGE: false,
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      GETALLTAXIS: [],
      GETALLOPERATORS: [],
      GETAllTAXIDRIVERS: [],
      DEPOSITTYPENAME: "",
      skip: 0,
      len: 4,
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      GETAllBILLINGITEMS: this.$store.getters["billingitems/GETALL"],
      VEHICLETRANSACTIONS: [],
      OPERATORTRANSACTIONS: [],
      DRIVERTRANSACTIONS: [],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      encodedImage: undefined,
      context: this,
      COMMENT: "",
      CAPTUREDBY: [],
      DEPOSITTYPES: [],
      DEPOSITTYPE: {},
      DEPOSITTYPEID: 0,
      selectedTitleId: undefined,
      selectedGenderId: undefined,
      selectedUserGroupId: undefined,
      paymentCreated: false,
      dataloadedABC: false,
      printReady: false,
      linetotal: 0,
      SELECTEDTAXIID: 0,
      TOTALINVOICEAMOUNT: "",
      vehicleAccountInfo: [],
      GETPAYMENTRESULTS: [],
      GETUPLOADEDFILES: [],
      BILLINGITEMS: [
        {
          billingitems_id: 0,
          lineamount: 0,
          lineqty: 1,
          linetotal: 0,
        },
      ],
      PREPAYMENTOBJECT: this.$store.getters["payments/GETPREPAYMENTDETAILS"],
      PrePayment: this.$route.params.PrePayment,
    };
  },
  beforeCreate: function () {
    document.body.classList.remove("bg-white");
  },
  beforeMount: function () {
   
    
    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
          allowClear: true,
        });
        $(".default-selectTaxi").val(null).trigger("change");
      }, 500);
    });
  },
  beforeDestroy: function () {
    this.clearAll(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  computed: {
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    CurrentDate: function () {
      return Date();
    },
    PAYMENTAMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT);
    },
    TRANSACTIONFEE: function () {
      let _transfee = 0;
      return _transfee;
      
    },
    TOTALMOUNT: function () {
      return Number(this.TOTALINVOICEAMOUNT) - this.TRANSACTIONFEE;
    },
  },
  watch: {
    SELECTEDTAXIID: function (val) {
      alert(val);
      if (val != null) {
        console.log("selected value: ", val);
      }
    },
  },
  methods: {
    ...mapActions(["getById", "getByTaxiAssId", "prepayment", "makepayment"]),
    fileadded: function (data) {
      //debugger;
      const context = $(".nav-link").data("context");
      context.GETUPLOADEDFILES = data;
      //alert(JSON.stringify(context.GETUPLOADEDFILES));
    },
    reloadInfo: function (context) {
      context.getAllTaxiByTaxiAssId(context, context.taxiassociation_id);
    },

    printNow: function (context, item) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: item.receiptid, preview: false },
      });
      window.open(routeData.href, "_blank");
    },
    printPreview: function (context, item) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: item.receiptid, preview: true },
      });
      window.open(routeData.href, "_blank");
    },
    printAll: function (context) {
      context.GETPAYMENTRESULTS.forEach((item) => {
        let routeData = context.$router.resolve({
          name: "printpayment",
          params: { id: item.receiptid, preview: false },
        });
        window.open(routeData.href, item.receiptid);
      });
    },
    reset: function (context) {
      $(".default-selectTaxiDrivers").val(null).trigger("change");
      $(".default-selectTaxi").val(null).trigger("change");
      $(".default-selectOperator").val(null).trigger("change");
      context.COMMENT = "";
      context.TOTALINVOICEAMOUNT = "";
    },

    getAllTaxiByTaxiAssId: (context, id) => {
      context.dataloadedABC = false;
      context.$store
        .dispatch("fleetmanagement/getAllClients", {
          router: context.$router,
          id,
        })
        .then((results) => {

          console.log("vehicles vue: ", results);
          debugger;
          context.GETALLTAXIS = results.data[0].data;

          context.dataloadedABC = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false,
          });

          $(".default-selectTaxi").on("change", function () {
            var taxiID = $(".default-selectTaxi option:selected").val();
            console.log("selected Taxi", taxiID);
            
          });
        });
    },
    setViewDetail(val) {
      this.GETSELECTED = Object.assign({}, val);
    },
    getAllBillingItemsByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("billingitems/getAllByTaxiAssID", {
          router: context.$router,
          id,
        })
        .then(() => {})
        .catch((error) => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    getvehicleTransactionsById: (context, id) => {
      
      context.$store
        .dispatch("sanraletag/getSanralVehicleTransactionsById", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("vehicles transactions results: ", data);
          context.VEHICLETRANSACTIONS = data;
          context.OPERATORTRANSACTIONS = [];
          context.DRIVERTRANSACTIONS = [];
        })
        .catch((error) => {
          console.log("vehicles vue page: ", error);
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },

    getCapturedBy: (context) => {
      
      context.$store
        .dispatch("systemusers/getSystemUsersCapturedBy", {
          associationid: context.taxiassociation_id,
          userid: context.USERID,
          router: context.$router,
        })
        .then((data) => {
          console.log("captured By results: ", data);
          context.CAPTUREDBY = data;
        })
        .catch((error) => {
          console.log("captured page: ", error);
          // context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },
    getDepositType: (context) => {
      
      context.$store
        .dispatch("fleetmanagement/getAllTransTypes", {
          router: context.$router,
          id: context.taxiassociation_id,
        })
        .then((results) => {
          console.log("captured By results: ", results);
          context.DEPOSITTYPES = results.data[0].data;
        })
        .catch((error) => {
          console.log("captured page: ", error);
          // context.$Notify.Error(error.message, "");
        })
        .finally(() => {

         
        });
    },

    getOperatorTransactionsById: (context, id) => {
      
      context.$store
        .dispatch("TO/getOperatorTransactionsByOperatorId", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("Operator transactions results: ", data);
          context.OPERATORTRANSACTIONS = data;
          context.VEHICLETRANSACTIONS = [];
          context.DRIVERTRANSACTIONS = [];
        })
        .catch((error) => {
          console.log("vehicles vue page: ", error);
          context.$Notify.Error(error.message, "");
        })
        .finally(() => {});
    },
    getdriverTransactionsById: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverTransactionsByDriverId", {
          id,
          skip: context.skip,
          len: context.len,
          router: context.$router,
        })
        .then((data) => {
          console.log("driver transactions results: ", data);
          context.DRIVERTRANSACTIONS = data;
          context.OPERATORTRANSACTIONS = [];
          context.VEHICLETRANSACTIONS = [];
          //context.GETAllTAXIDRIVERS = data
        })
        .catch((error) => {
          console.log("operators vue page: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },

    createpayment: (context) => {
      
      let AccountId = $(".default-selectTaxi option:selected").val();
    
      const _converteddate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-disabled-past").datepicker("getDate")
      );

      var taxiID = $("#selectcapturedby option:selected").val();

      context.DEPOSITTYPEID = context.DEPOSITTYPE.id;
      context.DEPOSITTYPENAME = context.DEPOSITTYPE.name;

      if(taxiID === 0 || context.DEPOSITTYPEID === 0){
        context.$Notify.Error("Invalid Deposit Type", "");
        return;
      }

      if(context.COMMENT.length === 0){
        context.$Notify.Error("Enter Reference", "");
        return;
      }

      const dropzone_uploads = [context.GETUPLOADEDFILES];
          const fileuploaded = [];

         // alert(JSON.stringify(dropzone_uploads));

          if (dropzone_uploads.length > 0) {
            dropzone_uploads.forEach((element) => {
              fileuploaded.push({
                customType: element.customType,
                fileId: element.fileId,
              });
            });
          }

          let transresponse = {
            reference: context.COMMENT.length > 0 ? context.COMMENT : "",
            document: fileuploaded,
          }
          //alert(JSON.stringify(transresponse));

      const Payload = {
        client_id: parseInt(AccountId),
        trans_type_id: parseInt(context.DEPOSITTYPEID),
        trans_date: _converteddate,
        transactionreference: context.DEPOSITTYPENAME,
        transactionamount: context.TOTALINVOICEAMOUNT,
        trans_response: JSON.stringify(transresponse),
        addedby: context.USERID,
      };

      context.$Notify
        .YesNoMore("Take Payment", "Are you sure?", false, {
          Payload,
          context,
        })
        .then((value) => {
          if (value) {
            
            value.context.$wait.start("createpayment");
            value.router = value.context.$router;
            value.context.$store
              .dispatch("fleetmanagement/takepayment", {
                router: value.router,
                payload: value.Payload
              })
              .then((_data) => {
                value.context.reloadInfo(value.context);
                value.context.reset(value.context);
                value.context.paymentCreated = true;
                value.context.printReady = false;
                value.context.$wait.end("createpayment");
                value.context.$Notify.Success("New Balance Is R" + _data.data[0].totalarrears, "");
              })
              .catch((error) => {
                value.context.$wait.end("createpayment");
                context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  mounted: function () {
    this.clearAll(this.context);
    this.getAllTaxiByTaxiAssId(this.context, this.taxiassociation_id);
    this.getDepositType(this.context);
    App.init();

    // Jquery Dependency

    //Get Data
    // $('#mySelect2').select2('data');

    $(".nav-link").data("context", this.context);

    $("#datepicker-disabled-past").datepicker({
      todayHighlight: true,
      autoclose: true,
    });


    $("#datepicker-disabled-past").datepicker("update", new Date());

    $(".nav-link").click(() => {
      setTimeout(() => {
        $(".default-select").select2({
          multiple: false,
          allowClear: true,
        });
        $(".default-selectOperator").val(null).trigger("change");
        $(".default-selectTaxi").val(null).trigger("change");
        $(".default-selectTaxiDrivers").val(null).trigger("change");
      }, 500);
    });

    Inputmask().mask(document.querySelectorAll(".inputmask"));

    $("#cash").focus();

    if (this.PrePayment) {
      this.createprepayment(this.context);
    }

    this.$store
      .dispatch("TA/getLOGOURL", {
        id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id,
      })
      .then((result) => {
        this.context.encodedImage = result;
      })
      .catch((err) => {});

  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.logo-image {
  width: 200px;
  height: 200px;
}
</style>

