<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FleetManagement'"
      :ActiveMenu="'ListOfClients'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="All Clients"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="FleetManagement"
        breadcrumbitem3="ListOfClients"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->
      <!-- begin row -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">All Operators</h4>
            </div>
            <!-- end panel-heading -->

            <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <router-link to="/FleetManagment/AddClient/:id" class="btn btn-white">
                  <i class="fa fa-plus"></i>
                  Add Client
                </router-link>
              </div>
            </div>

            <br />

            <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="panel-body">
              <table id="data-table-default" class="table table-striped table-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
                <!-- <table class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0"></table> -->
                <thead>
                  <tr>
                    <!-- <th width="1%" data-orderable="false"></th> -->
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">ID Number</th>
                    <th class="text-nowrap">Mobile No</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">No Of Vehicles</th>
                    <th class="text-nowrap">Outstanding Balance</th>
                    <th class="text-nowrap">Bad Debt</th>
                    <th class="text-nowrap">Contract Amount</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETOPERATORSBYASSID"
                    v-bind:key="item.taxi_operator_id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <!-- <td width="1%" class="f-s-600 text-inverse">{{++index}}</td> -->
                    <td>
                      <router-link
                        :to="{
                          name: 'listofvehicles',
                          params: { id: item.id_number },
                        }"
                        class=""
                        >{{ item.firstname }} {{ item.surname }}</router-link>
                      </td>
                    <td>{{item.id_number}}</td>
                    <td>{{item.mobilenumber}}</td>
                    <td>
                      <span v-if="item.is_active"
                        class="badge f-s-12 badge-success"
                      > Active</span>
                      <span v-if="!item.is_active"
                        class="badge f-s-12 badge-danger"
                      > Not Active</span>
                    </td>
                    <td>{{item.numberofvehicles}}</td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-danger': item.outstanding_balance > 0, 'badge-success':item.outstanding_balance <=0  }"
                      > {{item.outstanding_balance | currencywithSymbol}}</span>
                    </td>
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-danger': item.is_baddebt, 'label-secondary':!item.is_baddebt  }"
                      >{{item.is_baddebt}}</span>
                    </td>
                    <td>{{item.debitamount | currencywithSymbol}}</td>
                   <td>
                    <router-link
                        :to="{
                          name: 'fleetprintstatement',
                          params: { id: item.id },
                        }"
                        class="btn btn-sm btn-success width-80 m-r-2"
                        >Statement</router-link>
                   </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fleetmanagement");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      skip: 0,
      length: 1000000,
      GETOPERATORSBYASSID: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      taxiassociationid: this.$route.params.id || this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      // accountid: this.$store.getters["TaxiDrivers/GETDRIVERACCOUNT"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      dataLoaded: false,
      CANCELCONTRACT: {
        SystemUserId: this.$store.getters["auth/USERPROFILE"].system_usersid,
        DebtorPhoneNumber: "27826869834",
        contractReference: "",
        clientReference: "",
        mandateID: "",
      },
      CREATECONTRACT: {
        TaxiAssociationId : this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        TaxiOperatorId :1,
        SystemUserId : this.$store.getters["auth/USERPROFILE"].system_usersid,
        Frequency: "MNTH",
        CollectionDay: "01",
        ClientReference: "MobifyTracker",
        DebtorName: "Manyowa Nong",
        DebtorId: "8107055561083",
        DebtorIdType: "2", //* ID NUMBER */
        DebtorAccountNumber: "62358375155",
        DebtorAccountType: "01",
        DebtorBankId: "3",
        DebtorBranchNumber: "250805",
        DebtorPhoneNumber: "27826869834",
        CollectionAmount: 5,
        StartDate: "2023-02-06"
      }
    };
  },
  beforeMount: function () {
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
  },
  methods: {
    ...mapActions([
      "getAllClientsyTaxiAssId",
    ]),
    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },
    CancelNuPayContract: (context, item) => {
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          value.context.$wait.start("CancelNuPayContract-" + value.item.id);

            value.context.CANCELCONTRACT.DebtorPhoneNumber = value.item.mobilenumber;
            value.context.CANCELCONTRACT.contractReference = value.item.nupaycontract_ref;
            value.context.CANCELCONTRACT.ClientReference = value.item.id.toString();
            value.context.CANCELCONTRACT.mandateID = value.item.mandateid;

            value.context.$store
            .dispatch("fleetmanagement/CancelNuPayContract", value.context.CANCELCONTRACT)
            .then((results) => {
              if(results.Status){
                thvalue.contextis.context.$Notify.Success("CONTRACT DELETED","");
              } else {
                value.context.context.$Notify.Error("FAILED TO DELETE CONTRACT","");
              }
             
            }).finally(()=>{
          value.context.$wait.end("CancelNuPayContract-" + value.item.id);
        });
        }
      });
    },
    CaptureNuPayContract: (context, item) => {
      
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        
        if (value) {
          value.context.$wait.start("CaptureNuPayContract-" + value.item.id);

          value.context.$store
        .dispatch("fleetmanagement/CaptureNuPayContract", value.context.CREATECONTRACT)
        .then((results) => {
          debugger;
          if(!results.Status){
            value.context.context.$Notify.Error(results.Data.Status,
            results.Data.ErrorMessage.ErrorMessage);
            return;
          } else {

            value.context.CANCELCONTRACT.DebtorPhoneNumber = value.context.CREATECONTRACT.DebtorPhoneNumber;
            value.context.CANCELCONTRACT.contractReference = value.item.nupaycontract_ref;
            value.context.CANCELCONTRACT.ClientReference = value.item.id.toString();
            value.context.CANCELCONTRACT.mandateID = value.item.mandateid;

            if(value.context.CANCELCONTRACT.mandateID !== ""){
              value.context.$store
            .dispatch("fleetmanagement/CancelNuPayContract", value.context.CANCELCONTRACT)
            .then((results) => {
              if(results.Status){
                value.context.context.$Notify.Success("CONTRACT UPDATED","");
              } else {
                value.context.context.$Notify.Error("FAILED TO UPDATE CONTRACT","");
              }
             
            })
            } else {
              value.context.context.$Notify.Success("CONTRACT CAPTURED","");
            }

          }
        })
        .catch((error) => {
          value.context.context.$Notify.Error(error.Data.Status,
          error.Data.ErrorMessage.ErrorMessage);
        }).finally(()=>{
          value.context.$wait.end("CaptureNuPayContract-" + value.item.id);
        });
        }
      });
    },
    getbyid: (context, taxiassociationid) => {
      context
        .getAllClientsyTaxiAssId({
          router: context.$router,
          id: taxiassociationid,
        })
        .then((results) => {
          console.log("operators found: ", results);
          context.GETOPERATORSBYASSID = results.data[0].data;;
          context.dataLoaded = true;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          $("#data-table-default").DataTable(options);
        });
    },
    Delete: (context, id, deletedby) => {
      context.$Notify.YesNo(false, { context, id, deletedby }).then((value) => {
        
        if (value) {
          value.context.$wait.start("delete-" + value.id);

          value.context
            .markAsDeleted({
              router: value.context.$router,
              id: value.id,
              deletedby: value.deletedby,
            })
            .then((data) => {
              value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].deleted_by = value.deletedby;
              value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].is_active = false;

              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Success("Successfully Deleted!", value.id);
            })
            .catch((error) => {
              value.context.$wait.end("delete-" + value.id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    Restore: (context, id, restoredby) => {
      context.$Notify
        .YesNo(false, { context, id, restoredby })
        .then((value) => {
          
          if (value) {
            value.context.$wait.is("restore-" + value.id);

            value.context
              .restoreDeleted({
                router: value.context.$router,
                id: value.id,
                restoredby: value.restoredby,
              })
              .then(() => {
                value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].deleted_by = null;
                value.context.GETOPERATORSBYASSID.filter(item => item.taxi_operator_id === value.id)[0].is_active = true;
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Success("Successfully Deleted!", "");
              })
              .catch((error) => {
                value.context.$wait.end("restore-" + value.id);
                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {

    this.context = this; 

    this.getbyid(this.context, this.taxiassociationid);
    App.init();

    

    $(".byrole").addClass("hide");

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

