<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->



    <!-- begin #content -->
    <div id="content" class="app-content p-0 bg-component">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Mandates"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Mandates"
        breadcrumbitem3="All"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      

            <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL ACTIVE MANDATES</div>
                    <div class="stats-number">{{ TOTALACTIVEMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates: {{ TOTALACTIVEMANDATESCOUNT }}</div>
                  </div>
                </div>
              </div>

               <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL SUSPENDED MANDATES</div>
                    <div class="stats-number">{{ TOTALSUSPENDEDMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates: {{ TOTALSUSPENDEDMANDATESCOUNT }}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL WITHOUT CLIENTS MANDATES</div>
                    <div class="stats-number">{{ TOTALWITHOUTCLIENTSMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates: {{ TOTALWITHOUTCLIENTSMANDATESCOUNT }}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL COMPLETED MANDATES</div>
                    <div class="stats-number">{{ TOTALCOMPLETEDMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates: {{ TOTALCOMPLETEDMANDATESCOUNT }}</div>
                  </div>
                </div>
              </div>
               </div>

               <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">DEBICHECK MANDATES</div>
                    <div class="stats-number">{{ TOTALACTIVEDEBICHECKMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates</div>
                  </div>
                </div>
              </div>

               <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">MPS MANDATES</div>
                    <div class="stats-number">{{ TOTALACTIVEMPSMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL WITHOUT CLIENTS MANDATES</div>
                    <div class="stats-number">{{ TOTALWITHOUTCLIENTSMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates: {{ TOTALWITHOUTCLIENTSMANDATESCOUNT }}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL COMPLETED MANDATES</div>
                    <div class="stats-number">{{ TOTALCOMPLETEDMANDATES | newcurrencywithSymbol}}</div>
                    <div class="stats-desc">No Of Mandates: {{ TOTALCOMPLETEDMANDATESCOUNT }}</div>
                  </div>
                </div>
              </div>
               </div>

<!-- NOTES --> 
  <!-- 1. highlight client with No Mandates  --> 
 <!-- 1. Add  --> 

 <!-- begin nav-pills -->
      <ul class="nav nav-pills">
        <li class="nav-items">
          <a href="#nav-pills-tab-1" @click="getAllActiveMandates(context,taxiassociation_id)" data-toggle="tab" class="nav-link active">
            <span class="d-sm-none">Active Mandates</span>
            <span class="d-sm-block d-none">Active Mandates</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-2" @click="getAllSuspendedMandates(context,taxiassociation_id)" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Suspended Mandates</span>
            <span class="d-sm-block d-none">Suspended Mandates</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-3" @click="getAllNotAssignedMandates(context,taxiassociation_id)" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Mandates WithOut Clients</span>
            <span class="d-sm-block d-none">Mandates WithOut Clients</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-4" @click="getAllCompletedMandates(context,taxiassociation_id)" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Completed Mandates</span>
            <span class="d-sm-block d-none">Completed Mandates</span>
          </a>
        </li>
        <li class="nav-items">
          <a href="#nav-pills-tab-5" @click="getAllCancelledMandates(context,taxiassociation_id)" data-toggle="tab" class="nav-link">
            <span class="d-sm-none">Cancelled Mandates</span>
            <span class="d-sm-block d-none">Cancelled Mandates</span>
          </a>
        </li>
      </ul>
      <!-- end nav-pills -->

      <!-- begin tab-content -->
      <div class="tab-content">
        <!-- begin tab-pane -->
        <div class="tab-pane fade active show" id="nav-pills-tab-1">
          <!-- begin widget-table -->

          <div v-if="!dataLoaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="table-responsive">
              <table id="tableactivatemandates" class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
                <thead>
                  <tr>
                    <th class="text-nowrap">Mandate Id</th>
                    <th class="text-nowrap">Partner</th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Id No</th>
                    <th class="text-nowrap">Contract Amount</th>
                    <th class="text-nowrap">Bank</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Account No</th>
                    <th class="text-nowrap">Debit Amount</th>
                    <th class="text-nowrap">Date Loaded</th>
                    <th class="text-nowrap">Start Date</th>
                    <th class="text-nowrap">Installments</th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Tracking</th>
                    <th class="text-nowrap">Frequency</th>
                    <th class="text-nowrap">Debtor Name</th>
                    <th class="text-nowrap">Debtor Id</th>
                    <th class="text-nowrap">Debtor Mobile</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item) in GETALLACTIVEMANDATES"
                    v-bind:key="item.id"
                    :class="{
                    'warning': item.iscaution,
                    }"
                  >
                    <td>
                      <a href="javascript:void(0)"
                      class="clipboard"
                      :data-clipboard-text="`${item.mandateid}`"
                      >  {{item.mandateid}}</a>
                      </td>
                    <td>{{item.taxiassociation}}</td>
                    <td>
                      <select
                                    v-bind:id="index + '-UpdateTrans'"
                                    class="form-control selectClients"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="UpdateTrans(context, item, item.selectclient)"
                                    v-model="item.selectclient"
                                    v-if="item.iseditmode"
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="clientitem in GETCLIENTSBYASSID"
                                      :value="clientitem"
                                    >{{clientitem.firstname}} {{clientitem.surname}} - {{clientitem.id_number}}</option>
                  </select>
                  <a
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                    v-if="!item.iseditmode"
                  >
                    {{ item.firstname }} {{ item.surname }}
                  </a>
                    </td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0 clipboard"
                    :class="{
                    'badge-success': item.client_is_active,
                    'badge-danger': !item.client_is_active,
                    }"
                    :data-clipboard-text="`${item.id_number}`"
                      >  {{item.id_number}}</span>
                    </td>
                    <td>{{item.contractamount | newcurrencywithSymbol}}</td>
                    <td>{{item.debtorbank}}</td>
                    <td>{{item.debtoraccounttypename}}</td>
                    <td>{{item.debtoraccountnumber}}</td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0"
                    :class="{
                    'badge-danger': item.isunderbilling
                    }"
                      > {{item.instalmentamount | newcurrencywithSymbol}}</span>
                      </td>
                    <td>{{item.dateloaded | formatToShortDate}} </td>
                    <td>{{item.startdate | formatToShortDate}} </td>

                    <td>
                      <span
                    :class="{
                    'badge f-s-12 rounded-0 badge-warning': item.islowinstallment
                    }"
                      > {{item.instalment}}/{{item.instalments}}</span>
                    </td>
                    <td>{{item.debittype}}</td>
                    <td>{{item.tracking}}</td>
                    <td>{{item.frequency}}</td>
                    <td>{{item.debtorname}}</td>
                    <td>{{item.debtorid}}</td>
                    <td>{{item.debtorphonenumber}}</td>
                    <td width="10%">
                      <button
                      v-if="!item.iseditmode"
                        type="button"
                        class="btn btn-sm btn-secondary width-60 m-r-2"
                        @click="item.iseditmode = true"
                      >
                      Update
                  </button>
                  <button
                      v-if="!item.iseditmode"
                        type="button"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        @click="item.iseditmode = true"
                      >
                      Ticket
                  </button>
                      <button
                      v-if="!item.iseditmode"
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        @click="item.iseditmode = true"
                      >
                      Cancel
                  </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->

        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade nav-pills-tab-2" id="nav-pills-tab-2">
          <div v-if="!dataLoadedSuspend" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="table-responsive">
              <table id="tablesuspendedmandates" class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
                <thead>
                  <tr>
                    <th class="text-nowrap">Mandate Id</th>
                    <th class="text-nowrap">Partner</th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Id No</th>
                    <th class="text-nowrap">Contract Amount</th>
                    <th class="text-nowrap">Bank</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Account No</th>
                    <th class="text-nowrap">Debit Amount</th>
                    <th class="text-nowrap">Date Loaded</th>
                    <th class="text-nowrap">Start Date</th>
                    <th class="text-nowrap">Installments</th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Tracking</th>
                    <th class="text-nowrap">Frequency</th>
                    <th class="text-nowrap">Debtor Name</th>
                    <th class="text-nowrap">Debtor Id</th>
                    <th class="text-nowrap">Debtor Mobile</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item) in GETALLSUSPENDEDMANDATES"
                    v-bind:key="item.id"
                    :class="{
                    'warning': item.iscaution,
                    }"
                  >
                    <td>
                      <a href="javascript:void(0)"
                      class="clipboard"
                      :data-clipboard-text="`${item.mandateid}`"
                      >  {{item.mandateid}}</a>
                      </td>
                    <td>{{item.taxiassociation}}</td>
                    <td>
                      <select
                                    v-bind:id="mandateid + '-selectsuspendedclient'"
                                    class="form-control selectClients"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="UpdateTrans(context, item, item.selectclient)"
                                    v-model="item.selectclient"
                                    v-if="item.iseditmode"
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="clientitem in GETCLIENTSBYASSID"
                                      :value="clientitem"
                                    >{{clientitem.firstname}} {{clientitem.surname}} - {{clientitem.id_number}}</option>
                  </select>
                  <a
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                    v-if="!item.iseditmode"
                  >
                    {{ item.firstname }} {{ item.surname }}
                  </a>
                    </td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0 clipboard"
                    :class="{
                    'badge-success': item.client_is_active,
                    'badge-danger': !item.client_is_active,
                    }"
                    :data-clipboard-text="`${item.id_number}`"
                      >  {{item.id_number}}</span>
                    </td>
                    <td>{{item.contractamount | newcurrencywithSymbol}}</td>
                    <td>{{item.debtorbank}}</td>
                    <td>{{item.debtoraccounttypename}}</td>
                    <td>{{item.debtoraccountnumber}}</td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0"
                    :class="{
                    'badge-danger': item.isunderbilling
                    }"
                      > {{item.instalmentamount | newcurrencywithSymbol}}</span>
                      </td>
                    <td>{{item.dateloaded | formatToShortDate}} </td>
                    <td>{{item.startdate | formatToShortDate}} </td>

                    <td>
                      <span
                    :class="{
                    'badge f-s-12 rounded-0 badge-warning': item.islowinstallment
                    }"
                      > {{item.instalment}}/{{item.instalments}}</span>
                    </td>
                    <td>{{item.debittype}}</td>
                    <td>{{item.tracking}}</td>
                    <td>{{item.frequency}}</td>
                    <td>{{item.debtorname}}</td>
                    <td>{{item.debtorid}}</td>
                    <td>{{item.debtorphonenumber}}</td>
                    <td width="10%">
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-3">
          <div v-if="!dataLoadednotassigned" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="table-responsive">
              <table id="tablenotassignedmandates" class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
                <thead>
                  <tr>
                    <th class="text-nowrap">Mandate Id</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Contract Amount</th>
                    <th class="text-nowrap">Bank</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Account No</th>
                    <th class="text-nowrap">Debit Amount</th>
                    <th class="text-nowrap">Date Loaded</th>
                    <th class="text-nowrap">Start Date</th>
                    <th class="text-nowrap">Installments</th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Tracking</th>
                    <th class="text-nowrap">Frequency</th>
                    <th class="text-nowrap">Debtor Name</th>
                    <th class="text-nowrap">Debtor Id</th>
                    <th class="text-nowrap">Debtor Mobile</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item,index) in GETALLNOTASSIGNEDMANDATES"
                    v-bind:key="item.id"
                    :class="{
                    'success': item.is_active,
                    }"
                  >
                    <td>
                      <a href="javascript:void(0)"
                      class="clipboard"
                      :data-clipboard-text="`${item.mandateid}`"
                      >  {{item.mandateid}}</a>
                      </td>
                      <td>{{item.mandatestatus}}</td>
                   
                    <td>
                      <select
                                    v-bind:id="index + '-selectnotassigned'"
                                    class="form-control selectClients"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="UpdateNoAssignedTrans(context, item, item.selectclient)"
                                    v-if="item.iseditmode"
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="(clientitem,index) in GETCLIENTSBYASSID"
                                      :value="index"
                                    >{{clientitem.firstname}} {{clientitem.surname}} - {{clientitem.id_number}}</option>
                  </select>
                  <a
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                    v-if="!item.iseditmode"
                  >
                    {{ item.firstname }} {{ item.surname }}
                  </a>
                    </td>
                    <td>{{item.contractamount | newcurrencywithSymbol}}</td>
                    <td>{{item.debtorbank}}</td>
                    <td>{{item.debtoraccounttypename}}</td>
                    <td>{{item.debtoraccountnumber}}</td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0"
                    :class="{
                    'badge-danger': item.isunderbilling
                    }"
                      > {{item.instalmentamount | newcurrencywithSymbol}}</span>
                      </td>
                    <td>{{item.dateloaded | formatToShortDate}} </td>
                    <td>{{item.startdate | formatToShortDate}} </td>

                    <td>
                      <span
                    :class="{
                    'badge f-s-12 rounded-0 badge-warning': item.islowinstallment
                    }"
                      > {{item.instalment}}/{{item.instalments}}</span>
                    </td>
                    <td>{{item.debittype}}</td>
                    <td>{{item.tracking}}</td>
                    <td>{{item.frequency}}</td>
                    <td>{{item.debtorname}}</td>
                    <td>
                      <a href="javascript:void(0)"
                      class="clipboard"
                      :data-clipboard-text="`${item.debtorid}`"
                      >  {{item.debtorid}}</a>
                      </td>
                    <td>{{item.debtorphonenumber}}</td>
                    <td width="10%">
                      <button
                      v-if="item.fk_clients_id && !item.iseditmode && !item.saved"
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        @click="SaveTrans(context, item,index)"
                      >
                      Save
                  </button>
                      <button
                      v-if="!item.iseditmode && !item.saved"
                        type="button"
                        class="btn btn-sm btn-secondary width-60 m-r-2"
                        @click="EditNotAssigned(context, item,index)"
                      >
                      Edit
                  </button>
                  <button
                    v-if="item.iseditmode && !item.saved"
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        @click="DoneEditNotAssigned(context, item,index)"
                      >
                      Done
                </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
        </div>
        <!-- end tab-pane -->

        <!-- begin tab-pane -->
        <div class="tab-pane fade" id="nav-pills-tab-4">
          <div v-if="!dataloadedcompleted" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="table-responsive">
              <table id="tablecompletedmandates" class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
                <thead>
                  <tr>
                    <th class="text-nowrap">Mandate Id</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Contract Amount</th>
                    <th class="text-nowrap">Bank</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Account No</th>
                    <th class="text-nowrap">Debit Amount</th>
                    <th class="text-nowrap">Date Loaded</th>
                    <th class="text-nowrap">Start Date</th>
                    <th class="text-nowrap">Installments</th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Tracking</th>
                    <th class="text-nowrap">Frequency</th>
                    <th class="text-nowrap">Debtor Name</th>
                    <th class="text-nowrap">Debtor Id</th>
                    <th class="text-nowrap">Debtor Mobile</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item) in GETALLCOMPLETEDMANDATES"
                    v-bind:key="item.id"
                    :class="{
                    'success': item.is_active,
                    }"
                  >
                    <td>
                      <a href="javascript:void(0)"
                      class="clipboard"
                      :data-clipboard-text="`${item.mandateid}`"
                      >  {{item.mandateid}}</a>
                      </td>
                      <td>{{item.mandatestatus}}</td>
                   
                    <td>
                      <select
                                    v-bind:id="index + '-UpdateTrans'"
                                    class="form-control selectClients"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="UpdateTrans(context, item, item.selectclient)"
                                    v-model="item.selectclient"
                                    v-if="item.iseditmode"
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="clientitem in GETCLIENTSBYASSID"
                                      :value="clientitem"
                                    >{{clientitem.firstname}} {{clientitem.surname}} - {{clientitem.id_number}}</option>
                  </select>
                  <a
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                    v-if="!item.iseditmode"
                  >
                    {{ item.firstname }} {{ item.surname }}
                  </a>
                    </td>
                    <td>{{item.contractamount | newcurrencywithSymbol}}</td>
                    <td>{{item.debtorbank}}</td>
                    <td>{{item.debtoraccounttypename}}</td>
                    <td>{{item.debtoraccountnumber}}</td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0"
                    :class="{
                    'badge-danger': item.isunderbilling
                    }"
                      > {{item.instalmentamount | newcurrencywithSymbol}}</span>
                      </td>
                    <td>{{item.dateloaded | formatToShortDate}} </td>
                    <td>{{item.startdate | formatToShortDate}} </td>

                    <td>
                      <span
                    :class="{
                    'badge f-s-12 rounded-0 badge-warning': item.islowinstallment
                    }"
                      > {{item.instalment}}/{{item.instalments}}</span>
                    </td>
                    <td>{{item.debittype}}</td>
                    <td>{{item.tracking}}</td>
                    <td>{{item.frequency}}</td>
                    <td>{{item.debtorname}}</td>
                    <td>{{item.debtorid}}</td>
                    <td>{{item.debtorphonenumber}}</td>
                    <td width="10%">
                      <button
                      v-if="!item.iseditmode"
                        type="button"
                        class="btn btn-sm btn-secondary width-60 m-r-2"
                        @click="item.iseditmode = true"
                      >
                      Edit
                  </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
        </div>
        <!-- end tab-pane -->

          <!-- begin tab-pane -->
          <div class="tab-pane fade" id="nav-pills-tab-5">
          <div v-if="!dataloadedcanceled" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

            <!-- begin panel-body -->
            <div class="table-responsive">
              <table id="tablecancelledmandates" class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
                <thead>
                  <tr>
                    <th class="text-nowrap">Mandate Id</th>
                    <th class="text-nowrap">Status</th>
                    <th class="text-nowrap">Full Name</th>
                    <th class="text-nowrap">Contract Amount</th>
                    <th class="text-nowrap">Bank</th>
                    <th class="text-nowrap">Account Type</th>
                    <th class="text-nowrap">Account No</th>
                    <th class="text-nowrap">Debit Amount</th>
                    <th class="text-nowrap">Date Loaded</th>
                    <th class="text-nowrap">Start Date</th>
                    <th class="text-nowrap">Installments</th>
                    <th class="text-nowrap">Type</th>
                    <th class="text-nowrap">Tracking</th>
                    <th class="text-nowrap">Frequency</th>
                    <th class="text-nowrap">Debtor Name</th>
                    <th class="text-nowrap">Debtor Id</th>
                    <th class="text-nowrap">Debtor Mobile</th>
                    <th class="text-nowrap hidden-print">Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item) in GETALLCANCELLEDMANDATES"
                    v-bind:key="item.id"
                    :class="{
                    'success': item.is_active,
                    }"
                  >
                    <td>
                      <a href="javascript:void(0)"
                      class="clipboard"
                      :data-clipboard-text="`${item.mandateid}`"
                      >  {{item.mandateid}}</a>
                      </td>
                      <td>{{item.mandatestatus}}</td>
                   
                    <td>
                      <select
                                    v-bind:id="index + '-UpdateTrans'"
                                    class="form-control selectClients"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="UpdateTrans(context, item, item.selectclient)"
                                    v-model="item.selectclient"
                                    v-if="item.iseditmode"
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="clientitem in GETCLIENTSBYASSID"
                                      :value="clientitem"
                                    >{{clientitem.firstname}} {{clientitem.surname}} - {{clientitem.id_number}}</option>
                  </select>
                  <a
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                    v-if="!item.iseditmode"
                  >
                    {{ item.firstname }} {{ item.surname }}
                  </a>
                    </td>
                    <td>{{item.contractamount | newcurrencywithSymbol}}</td>
                    <td>{{item.debtorbank}}</td>
                    <td>{{item.debtoraccounttypename}}</td>
                    <td>{{item.debtoraccountnumber}}</td>
                    <td>
                      <span
                      class="badge f-s-12 rounded-0"
                    :class="{
                    'badge-danger': item.isunderbilling
                    }"
                      > {{item.instalmentamount | newcurrencywithSymbol}}</span>
                      </td>
                    <td>{{item.dateloaded | formatToShortDate}} </td>
                    <td>{{item.startdate | formatToShortDate}} </td>

                    <td>
                      <span
                    :class="{
                    'badge f-s-12 rounded-0 badge-warning': item.islowinstallment
                    }"
                      > {{item.instalment}}/{{item.instalments}}</span>
                    </td>
                    <td>{{item.debittype}}</td>
                    <td>{{item.tracking}}</td>
                    <td>{{item.frequency}}</td>
                    <td>{{item.debtorname}}</td>
                    <td>{{item.debtorid}}</td>
                    <td>{{item.debtorphonenumber}}</td>
                    <td width="10%">
                      <button
                      v-if="!item.iseditmode"
                        type="button"
                        class="btn btn-sm btn-secondary width-60 m-r-2"
                        @click="item.iseditmode = true"
                      >
                      Edit
                  </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end panel-body -->
        </div>
        <!-- end tab-pane -->
        <!-- begin tab-pane -->
      </div>
      <!-- end tab-content -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fleetmanagement");

export default {
  name: "ManageOperators",
  data: function () {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
      skip: 0,
      length: 100000,
      geolocation: {
        latitude: null,
        longitude: null,
      },
      GETALLACTIVEMANDATES: [],
      GETALLSUSPENDEDMANDATES: [],
      GETMERCHANTSBYASSID: [],
      GETALLNOTASSIGNEDMANDATES: [],
      TOTALINACTIVEMANDATES: [],
      GETALLCOMPLETEDMANDATES: [],
      GETALLCANCELLEDMANDATES: [],
      dataLoaded: false,
      dataLoadedSuspend: false,
      dataLoadednotassigned: false,
      dataloadedcompleted: false,
      dataSuspend: false,
      dataNotAssigned: false,
      datacancalledmandates: false,
      dataloadedcanceled: false,
      dataActiveMandates: false,
      GETCLIENTSBYASSID: [],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
    };
  },
  beforeMount: function () {
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
  },
  computed: {
    ...mapGetters({}),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    TOTALCOMPLETEDMANDATES: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLCOMPLETEDMANDATES.length; i++) {
        results += this.GETALLCOMPLETEDMANDATES[i].instalmentamount;
      }
      return results;
    },
    TOTALCOMPLETEDMANDATESCOUNT: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLCOMPLETEDMANDATES.length; i++) {
        results += 1;
      }
      return results;
    },
    TOTALWITHOUTCLIENTSMANDATES: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLNOTASSIGNEDMANDATES.length; i++) {
        results += this.GETALLNOTASSIGNEDMANDATES[i].instalmentamount;
      }
      return results;
    },
    TOTALWITHOUTCLIENTSMANDATESCOUNT: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLNOTASSIGNEDMANDATES.length; i++) {
        results += 1;
      }
      return results;
    },
    TOTALSUSPENDEDMANDATES: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLSUSPENDEDMANDATES.length; i++) {
        results += this.GETALLSUSPENDEDMANDATES[i].instalmentamount;
      }
      return results;
    },
    TOTALSUSPENDEDMANDATESCOUNT: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLSUSPENDEDMANDATES.length; i++) {
        results += 1;
      }
      return results;
    },
    TOTALACTIVEMANDATES: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLACTIVEMANDATES.length; i++) {
        results += this.GETALLACTIVEMANDATES[i].instalmentamount;
      }
      return results;
    },
    TOTALACTIVEMANDATESCOUNT: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLACTIVEMANDATES.length; i++) {
        results += 1
      }
      return results;
    },
    TOTALACTIVEDEBICHECKMANDATES: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLACTIVEMANDATES.length; i++) {
        if (this.GETALLACTIVEMANDATES[i].debittype === "DEBICHECK") {
          results += this.GETALLACTIVEMANDATES[i].instalmentamount;
        }
      }
      return results;
    },
    TOTALACTIVEMPSMANDATES: function () {
      var results = 0.0;
      debugger
      for (var i = 0; i < this.GETALLACTIVEMANDATES.length; i++) {
        if (this.GETALLACTIVEMANDATES[i].debittype === "MPS") {
          results += this.GETALLACTIVEMANDATES[i].instalmentamount;
        }
      }
      return results;
    }, 
  },
  methods: {
    ...mapActions(["getAllClients","UpdateNotAssignedMandate"]),
    DoneEditNotAssigned: (context, item,index) => {
      $("#" + index + "-selectnotassigned").select2('destroy');
      item.iseditmode = false;
    },
    EditNotAssigned: (context, item,index) => {
      debugger;
      item.iseditmode = true;

       setTimeout(() => {
            $("#" + index + "-selectnotassigned").select2({
                multiple: false,
                placeholder: "Select Client",
                allowClear: false,
          });
          $("#" + index + "-selectnotassigned").on("select2:select", function(e) {

            debugger;

            var context = $(document).data("context");

            var item = context.GETALLNOTASSIGNEDMANDATES[index];

            var selectedclient = context.GETCLIENTSBYASSID[parseInt(e.params.data.id)];

            context.UpdateNoAssignedTrans(context, item, selectedclient)
          
        });
          }, 100);
      
    },
    SaveTrans: (context, item,newitem) => {

    debugger
    item.saved = true;
    context
        .UpdateNotAssignedMandate({
          router: context.$router,
          payload: {
            taxiassid: item.fk_taxiassociation_id,
            mandatesid: item.mandateid,
            clientid: item.fk_clients_id,
            updatedby: context.USERID,
          },
        })
        .then((results) => {
          context.$Notify.Toast("Saved", true,false);
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          
        });

    },
    UpdateNoAssignedTrans: (context, item,newitem) => {

      debugger
      item.fk_clients_id = newitem.id;
      item.firstname = newitem.firstname;
      item.surname = newitem.surname;
      item.id_number = newitem.id_number;
      item.contractamount = newitem.contractamount;
      item.fk_taxiassociation_id = newitem.fk_taxiassociation_id;
    },
    getAllFleetClients: (context, taxiassociationid) => {
      context
        .getAllClients({
          router: context.$router,
          id: taxiassociationid,
        })
        .then((results) => {
          
          context.GETCLIENTSBYASSID = results.data[0].data;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          //setTimeout(() => {
          
          //}, 100);
        });
    },
    getAllActiveMandates: (context, id) => {
      context.dataLoaded = false;
      context.$store
        .dispatch("fleetmanagement/getAllActiveMandates", {
          router: context.$router,
          id,
        })
        .then((results) => {

          console.log("vehicles vue: ", results);
          debugger;
          context.GETALLACTIVEMANDATES = results.data[0].data;

          context.dataLoaded = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


            if(!context.dataActiveMandates){
            $("#tableactivatemandates").DataTable(options);
            context.dataActiveMandates = true;
          }

        });
    },
    getAllSuspendedMandates: (context, id) => {
      context.dataLoadedSuspend = false;
      context.$store
        .dispatch("fleetmanagement/getAllSuspendedMandates", {
          router: context.$router,
          id,
        })
        .then((results) => {

          console.log("GETALLSUSPENDEDMANDATES vue: ", results);
          debugger;
          context.GETALLSUSPENDEDMANDATES = results.data[0].data;

          context.dataLoadedSuspend = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          if(!context.dataSuspend){
            $("#tablesuspendedmandates").DataTable(options);
            context.dataSuspend = true;
          }
         

        });
    },
    getAllNotAssignedMandates: (context, id) => {
      context.dataLoadednotassigned = false;
      context.$store
        .dispatch("fleetmanagement/getAllNotAssignedMandates", {
          router: context.$router,
          id,
        })
        .then((results) => {

          console.log("vehicles vue: ", results);
          debugger;
          context.GETALLNOTASSIGNEDMANDATES = results.data[0].data;

          context.dataLoadednotassigned = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          if(!context.dataNotAssigned){
            $("#tablenotassignedmandates").DataTable(options);
            context.dataNotAssigned = true;
          }

        });
    },
    getAllCompletedMandates: (context, id) => {
      context.dataloadedcompleted = false;
      context.$store
        .dispatch("fleetmanagement/getAllCompletedMandates", {
          router: context.$router,
          id,
        })
        .then((results) => {

          console.log("vehicles vue: ", results);
          debugger;
          context.GETALLCOMPLETEDMANDATES = results.data[0].data;

          context.dataloadedcompleted = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          if(!context.datacompletedmandates){
            $("#tablecompletedmandates").DataTable(options);
            context.datacompletedmandates = true;
          }
         
        });
    },
    getAllCancelledMandates: (context, id) => {
      context.dataloadedcanceled = false;
      context.$store
        .dispatch("fleetmanagement/getAllCancelledMandates", {
          router: context.$router,
          id,
        })
        .then((results) => {

          console.log("vehicles vue: ", results);
          debugger;
          context.GETALLCANCELLEDMANDATES = results.data[0].data;

          context.dataloadedcanceled = true;

        })
        .catch((error) => {
          console.log("vehicles errors: ", error);
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {

          var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false
                  };


          if(!context.datacancalledmandates){
            $("#tablecancelledmandates").DataTable(options);
            context.datacancalledmandates = true;
          }
          
        });
    },
  },
  beforeDestroy: function () {},
  mounted: function () {
    $(document).data("context", this);
    App.init();

    this.getAllActiveMandates(this, this.taxiassociation_id);
    this.getAllFleetClients(this, this.taxiassociation_id);

    

    var clipboard = new ClipboardJS(".clipboard");

    clipboard.on("success", function (e) {
      $(e.trigger).tooltip({
        title: "Copied",
        placement: "top",
      });
      $(e.trigger).tooltip("show");
      setTimeout(function () {
        var bootstrapVersion = handleCheckBootstrapVersion();
        if (bootstrapVersion >= 3 && bootstrapVersion < 4) {
          $(e.trigger).tooltip("destroy");
        } else if (bootstrapVersion >= 4 && bootstrapVersion < 5) {
          $(e.trigger).tooltip("dispose");
        }
      }, 500);
    });

    // TableManageCombine.init();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

