<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'Dashboard'"
      :ActiveMenu="'Summary'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="Tracker Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="Dashboard"
          breadcrumbitem3="Tracker Dashboard"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

				<div id="advance-daterange" class="btn btn-default btn-block text-left m-b-10 width-400">
                        <i class="fa fa-caret-down pull-right m-t-2"></i>
                        <span></span>
                         <div v-show="$wait.is('loading')" class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
                      </div>
                      

        
        
        <!-- begin row -->
        <div class="row">
          
               <!-- begin col-3 -->
               <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              
             <div class="stats-content">
                <div class="stats-title">Total Commission</div>
                <div class="stats-number">{{GETDASHBOARDCARDSBASE.totalcommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              
             <div class="stats-content">
                <div class="stats-title">Yearly Commission</div>
                <div class="stats-number">{{GETDASHBOARDCARDSBASE.yearlycommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
            <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">Previous Quarter</div>
                <div class="stats-number">{{GETDASHBOARDCARDSBASE.prevquarterlycommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
           <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">This Quarter</div>
                <div class="stats-number">{{GETDASHBOARDCARDSBASE.quarterlycommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">Last Month's Commission</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.lastmonthcommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-2 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
             
              <div class="stats-content">
                <div class="stats-title">This Month's Commission</div>
                <div class="stats-number">{{GETDASHBOARDCARDSBASE.thismonthcommission | newcurrencywithSymbol}}</div>
                 <div class="stats-desc"></div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           
        </div>

        <!-- end row -->

       

          <!-- begin page-header -->
        <h1 class="page-header">Tracker Finance Summary

        </h1>
        <!-- end page-header -->

        <!-- begin row -->

        <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-4 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Subscriptions</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalinvoices | newcurrencywithSymbol}}</div>

                <div class="stats-desc">Money that is in our account</div>
              </div>
            </div>
          </div>
          <!-- begin col-3 -->

          <!-- begin col-3 -->
          <div class="col-lg-4 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Payments Received</div>
                <div
                  class="stats-number"
                >{{ GETDASHBOARDCARDS.totalpaymentsreceived | newcurrencywithSymbol}}</div>

                <div class="stats-desc">The expected amount</div>
              </div>
            </div>
          </div>
          <!-- begin col-3 -->

          <!-- begin col-3 -->
          <div class="col-lg-4 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="ion-logo-usd"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Commission Received</div>
                <div
                  class="stats-number"
                >{{GETDASHBOARDCARDS.totalcashreceived | newcurrencywithSymbol}}</div>

                <div class="stats-desc">Number Of Cash Received By Collectors</div>
              </div>
            </div>
          </div>
          <!-- begin col-3 -->

          <div class="col-lg-6 col-md-6 hide">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Yearly Turnover Comparison</h4>
              </div>
              <div class="panel-body">
                <canvas id="morris-donut-chart" class="height-sm"></canvas>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 hide">
            <!-- begin panel -->
            <div class="panel panel-inverse">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Quartely Comparison Turnover Comparison</h4>
              </div>
              <div class="panel-body">
                <canvas id="morris-donut-chart" class="height-sm"></canvas>
              </div>
            </div>
          </div>
        </div>

        <!-- end row -->

        <!-- begin row -->
        <div class="row">

          <!-- begin col-xl-8 -->
          <div class="col-xl-8">
           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">3 Month Of Weekly Statistics</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-threemonths" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->


             <!-- begin panel -->
             <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">12 Month Statistics</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-12months" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">30 Days Collections</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chart-monthly" class="height-sm interactive-chart"></div>
              </div>
            </div>
            <!-- end panel -->


          </div>

            <!-- begin col-xl-4 -->
          <div class="col-xl-4">

             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Weekly Collections</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Total Cash</th>
                        <th>Total Payments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETDASHBOARDFINSTATS.weeklycollectionrank"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.agentname}}
                        </td>
                        <td class="text-center">{{item.cashpayment | newcurrencywithSymbol}}</td>
                        <td class="text-center">{{item.receiptsamounts | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

             <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Overall Collections</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Total Cash</th>
                        <th>Total Payments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETDASHBOARDFINSTATS.collectionrank"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                         {{item.agentname}}
                        </td>
                        <td class="text-center">{{item.cashpayment | newcurrencywithSymbol}}</td>
                        <td class="text-center">{{item.receiptsamounts | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

            	

          </div>
          <!-- end col-xl-4 -->

        
         
        </div>
        <!-- end row -->

      

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "fleetmanagement_dashboard"
);

export default {
  name: "sysadmin_dashboard",
  data: function() {
    return {
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
	  taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
	  GETDASHBOARDCARDS: [],
    GETDASHBOARDCARDSBASE: [],
	  GETDASHBOARDFINSTATS: [],
    startDate: null,
    endDate: null,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["",""])
  },
  methods: {
    ...mapActions(["getdashboardcards","getcollectionmanagerdashboardfinstats"]),
    init: function() {},
    loadData: function() {

      this.$wait.start("loading");

      const router = this.$router;

      var payload1 = {
      taxiassociationsId: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload1 })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        debugger;

        this.GETDASHBOARDCARDSBASE = parsedResultOP.data[0];

      
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

     

var payload = {
      taxiassociationsId: this.taxiassociationid,
      startDate: this.$Toolkit.ConvertToServerDate(this.startDate),
      endDate: this.$Toolkit.ConvertToServerDate(this.endDate),
    };

    //const context =  $(document).data("context");

    this.getcollectionmanagerdashboardcards({ router, payload })
      .then(parsedResultOP => {
        

		
		  this.GETDASHBOARDCARDS = parsedResultOP;

      this.$wait.end("loading");

	  })
      .catch(error => {
        //context.$wait.end("generatestatement");
        
        this.$wait.end("loading");
	  });
	  
	  this.getcollectionmanagerdashboardfinstats({ router, payload })
      .then(parsedResultOP => {
		  const context =  $(document).data("context");

		  context.GETDASHBOARDFINSTATS = parsedResultOP;

		  handleInteractiveChartMonthly();
      handleInteractiveChart12months();
    	handleInteractiveChartWeekly();
	  })
      .catch(error => {
		//context.$wait.end("generatestatement");
		
      });

    var handleInteractiveChartWeekly = function() {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-threemonths").length !== 0) {

		const context =  $(document).data("context");


		var data1 =  context.GETDASHBOARDFINSTATS.threemonthsinvoices.map((item, index) => {
          return [item.row_number,item.totalinvoices]
        });
		
        var data2 = context.GETDASHBOARDFINSTATS.threemonthspayments.map((item, index) => {
          return [item.row_number,item.totalpayments]
		});
		
        var xLabel = context.GETDASHBOARDFINSTATS.threemonthtimeseries.map((item, index) => {
          return [item.row_number,item.rdate]
		});

        $.plot(
          $("#interactive-chart-threemonths"),
          [
            {
              data: data1,
              label: "Invoices",
              color: COLOR_BLUE,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0
            },
            {
              data: data2,
              label: "Payments",
              color: COLOR_GREEN,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0
            }
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            dataLabels: {
              enabled: true,
              style: {
                  colors: ['#333']
              },
              offsetX: 30
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true
            }
          }
        );
        var previousPoint = null;
        $("#interactive-chart-threemonths").bind("plothover", function(
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

    var handleInteractiveChart12months = function() {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-12months").length !== 0) {

		const context =  $(document).data("context");


		var data1 =  context.GETDASHBOARDFINSTATS.months12invoices.map((item, index) => {
          return [item.row_number,item.totalinvoices]
        });
		
        var data2 = context.GETDASHBOARDFINSTATS.months12payments.map((item, index) => {
          return [item.row_number,item.totalpayments]
		});
		
        var xLabel = context.GETDASHBOARDFINSTATS.months12timeseries.map((item, index) => {
          return [item.row_number,item.rdate]
		});

        $.plot(
          $("#interactive-chart-12months"),
          [
            {
              data: data1,
              label: "Invoices",
              color: COLOR_BLUE,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0
            },
            {
              data: data2,
              label: "Payments",
              color: COLOR_GREEN,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0
            }
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            dataLabels: {
              enabled: true,
              style: {
                  colors: ['#333']
              },
              offsetX: 30
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2,
            },
            
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true
            }
          }
        );
        var previousPoint = null;
        $("#interactive-chart-12months").bind("plothover", function(
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };

    var handleInteractiveChartMonthly = function() {
      "use strict";
      function showTooltip(x, y, contents) {
        $('<div id="tooltip" class="flot-tooltip">' + contents + "</div>")
          .css({
            top: y - 45,
            left: x - 55
          })
          .appendTo("body")
          .fadeIn(200);
      }
      if ($("#interactive-chart-monthly").length !== 0) {
		
	const context =  $(document).data("context");


		// var data1 =  context.GETDASHBOARDFINSTATS.dailymonthsinvoicesdaily.map((item, index) => {
        //   return [item.row_number,item.totalinvoices]
        // });
		
        var data2 = context.GETDASHBOARDFINSTATS.dailymonthspaymentsdaily.map((item, index) => {
          return [item.row_number,item.totalpayments]
		});
		
        var xLabel = context.GETDASHBOARDFINSTATS.dailymonthtimeseriesdaily.map((item, index) => {
          return [item.row_number,item.rdate]
		});


        $.plot(
          $("#interactive-chart-monthly"),
          [
            // {
            //   data: data1,
            //   label: "Invoices",
            //   color: COLOR_BLUE,
            //   lines: { show: true, fill: false, lineWidth: 2 },
            //   points: { show: true, radius: 3, fillColor: COLOR_WHITE },
            //   shadowSize: 0
            // },
            {
              data: data2,
              label: "Payments",
              color: COLOR_GREEN,
              lines: { show: true, fill: false, lineWidth: 2 },
              points: { show: true, radius: 3, fillColor: COLOR_WHITE },
              shadowSize: 0
            }
          ],
          {
            xaxis: {
              ticks: xLabel,
              tickDecimals: 0,
              tickColor: COLOR_BLACK_TRANSPARENT_2
            },
            yaxis: {
              ticks: 10,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              min: 0,
            },
            grid: {
              hoverable: true,
              clickable: true,
              tickColor: COLOR_BLACK_TRANSPARENT_2,
              borderWidth: 1,
              backgroundColor: "transparent",
              borderColor: COLOR_BLACK_TRANSPARENT_2
            },
            legend: {
              labelBoxBorderColor: COLOR_BLACK_TRANSPARENT_2,
              margin: 10,
              noColumns: 1,
              show: true
            }
          }
        );
        var previousPoint = null;
        $("#interactive-chart-monthly").bind("plothover", function(
          event,
          pos,
          item
        ) {
          $("#x").text(pos.x.toFixed(2));
          $("#y").text(pos.y.toFixed(2));
          if (item) {
            if (previousPoint !== item.dataIndex) {
              previousPoint = item.dataIndex;
              $("#tooltip").remove();
              var y = item.datapoint[1].toFixed(2);

              var content = item.series.label + " " + y;
              showTooltip(item.pageX, item.pageY, content);
            }
          } else {
            $("#tooltip").remove();
            previousPoint = null;
          }
          event.preventDefault();
        });
      }
    };


    },
  },
  mounted: function() {
	  $(document).data("context", this);
    App.init();

    $("#advance-daterange span").html(
      moment().startOf("month")
        .format("MMMM D, YYYY") +
        " - " +
        moment().endOf("month").format("MMMM D, YYYY")
    );

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().startOf("month"),
        endDate:  moment().endOf("month"),
        minDate: "01/01/2015",
        maxDate: "12/31/2090",
        dateLimit: { days: 365 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        

        const context = $(document).data("context");

        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );

        

        context.loadData();
        
      }
    );

    this.startDate = $('#advance-daterange').data('daterangepicker').startDate.format("D MMMM YYYY");
    this.endDate = $('#advance-daterange').data('daterangepicker').endDate.format("D MMMM YYYY");

    

    this.loadData();

    
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>