<template>
  <!-- begin invoice -->
  <div class="invoice">
    <!-- begin invoice-Logo -->
    
    <!-- begin invoice-Logo -->
    <!-- begin invoice-company -->
    <div class="invoice-company text-inverse f-w-600">
      <span class="pull-right hidden-print">
        <a
          href="javascript:;"
          @click="$router.back()"
          class="btn btn-lg btn-primary m-r-20 m-b-10 p-l-5"
        >
          <i class="fa fa-arrow-left t-plus-1 text-white fa-fw fa-lg"></i> Back
        </a>
        <a
          href="javascript:;"
          :disabled="$wait.is('generatestatement')"
          @click="generatetatement(context)"
          :class="{'btn btn-lg btn-success m-r-20 m-b-10 p-l-5 hide': !$wait.is('generatestatement'),
            'btn btn-lg btn-grey m-b-10 p-l-5 hide': $wait.is('generatestatement')
            }"
        >
          <i
            class="fa fa-history t-plus-1 text-white fa-fw fa-lg"
            v-show="!$wait.is('generatestatement')"
          ></i>

          <i
            class="fa fa-spinner fa-spin t-plus-1 text-white fa-fw fa-lg"
            v-show="$wait.is('generatestatement')"
          ></i>

          <span>Generate Statement</span>
        </a>
        <a
          href="javascript:;"
          v-if="statementGenerated"
          @click="print(context)"
          class="btn btn-lg btn-white m-r-20 m-b-10 p-l-5"
        >
          <i class="fa fa-print t-plus-1 fa-fw fa-lg"></i> Print Statement
        </a>
      </span>
      Statement Of Account
    </div>

      <div v-if="!dataloaded" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>


    <!-- begin invoice-content -->
    <div class="invoice-content">
       <!-- begin invoice-price -->
       <div class="invoice-price">
        <div class="invoice-price-left">
          <div class="invoice-price-row">
            <div class="sub-price">
              <small></small>
              <span class="text-inverse"></span>
            </div>

            <div class="sub-price">
              <small></small>
              <span class="text-inverse"></span>
            </div>
          </div>
        </div>
        <div class="invoice-price-right" style="white-space: pre">
          <small class>
            <b>OVERDUE BAL</b>
          </small>
          <span
            class="f-w-600"
          >{{ GENERATEDSTATEMENT.length > 0 ? GENERATEDSTATEMENT[0].available_balance : '' | currencywithSymbol}}</span>
        </div>
      </div>
      <!-- end invoice-price -->
      <!-- begin table-responsive -->
      <div class="table-responsive">
        <table class="table table-invoice">
          <thead>
            <tr>
              <th >Date</th>
              <th >DESCRIPTION</th>
              <th class="text-center" >INVOICE</th>
              <th class="text-center" >PAYMENT</th>
              <th class="text-right" >BALANCE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in GENERATEDSTATEMENT" v-bind:key="item.id">
              <td>
                <span class="text-inverse">{{item.transaction_date | formatToShortDate }}</span>
                <br />
              </td>
              <td>
                <span :style="{color: !item.isinvoice ? '#4CD964!important' : '#d62728!important'}" class="text-inverse">{{item.transaction_reference}}</span>
              </td>
              
              <td class="text-center">
                {{ item.isinvoice ? item.transaction_amount : '' | currencywithSymbol}}
              </td>
              <td class="text-center">
                {{ !item.isinvoice ? item.transaction_amount : '' | currencywithSymbol}}
              </td>
              <td class="text-right">{{item.remaning_balance | currencywithSymbol}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- end table-responsive -->
     
    </div>
    <!-- end invoice-content -->
    <!-- begin invoice-note -->
    <div class="invoice-note">
      * Make all cheques payable to {{SELECTEDTAXIASSOCIATION._name}}
      <br />* Payment is due within 30 days
      <br />
    </div>
    <!-- end invoice-note -->
    <!-- begin invoice-footer -->
    <div class="invoice-footer">
      <p class="text-center m-b-5 f-w-600">THANK YOU FOR YOUR BUSINESS</p>
      <p class="text-center">
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          T:{{SELECTEDTAXIASSOCIATION._telephone | HumanizeMobile}}
        </span>
        <span class="m-r-10">
          <i class="fa fa-fw fa-lg fa-phone-volume"></i>
          M:{{SELECTEDTAXIASSOCIATION._mobilenumber | HumanizeMobile}}
        </span>
      </p>
    </div>
    <!-- end invoice-footer -->
  </div>
  <!-- end invoice -->
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("fleetmanagement");

export default {
  name: "Print Passanger List",
  data: function() {
    return {
      context: this,
      id: this.$route.params.id,
      GETALLTAXIS: [],
      GETALLOPERATORS: this.$store.getters["TO/GETOPERATORSBYACCOUNTNO"],
      GETAllTAXIDRIVERS: this.$store.getters["TaxiDrivers/GETDRIVERSBYASSIDNOPIC"],
      GETAllTAXIMODELS: this.$store.getters["TV/GETALLMODEL"],
      taxiassociation_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
        .id,
      SELECTEDTAXIASSOCIATION: this.$store.getters[
        "auth/SELECTEDTAXIASSOCIATION"
      ],
      dataloaded: true,
      showDuringPrint: true,
      statementGenerated: false,
      encodedImage: undefined,
      startDate: "",
      endDate: "",
      statement: {
        name: "",
        name2: "",
        address: "",
        suburb_name: "",
        postalcode: "",
        mobilenumer: ""
      },
      statementInfo: [],
      GENERATEDSTATEMENT: [],
    };
  },
  beforeCreate: function() {},
  beforeMount: function() {
  },
  components: {},

  computed: {
    ...mapGetters([""])
  },
  watch: {},
  methods: {
    ...mapActions([
      "getAllClientTransactions",
    ]),
    printPayment: function(context, receiptid) {
      let routeData = context.$router.resolve({
        name: "printpayment",
        params: { id: receiptid, preview: true }
      });
      window.open(routeData.href, "_blank");
    },
    printInvoice: function(context, invoice_id) {
      let routeData = context.$router.resolve({
        name: "printinvoice",
        params: { id: invoice_id, preview: true }
      });
      window.open(routeData.href, "_blank");
    },
    clear: context => {
      context.clearData();
    },
    generatetatement: context => {

      const router = context.$router;

      context.$wait.start("generatestatement");

        context
        .getAllClientTransactions({
          router: context.$router,
          id: context.id,
        })
          .then(parsedResultOP => {
            console.log("statements found: ",parsedResultOP);
            if (parsedResultOP.status) {
              if (parsedResultOP.data.length === 0) {
                context.$Notify.Error("No Information Found", "");
                context.statementGenerated = false;
              } else {
                debugger;
                context.GENERATEDSTATEMENT = parsedResultOP.data[0].data;
                context.statementGenerated = true;
                
              }
            }
            context.$wait.end("generatestatement");
          })
          .catch(error => {
            
            console.log("statement errors: ",error);
            context.$wait.end("generatestatement");
          });
        
    },
    print: context => {
      var randomnumber = Math.floor(Math.random() * 100 + 1);

      context.showDuringPrint = false;
      document.title = randomnumber;
      window.print();
      context.showDuringPrint = true;
      document.title = SELECTEDTAXIASSOCIATION._name;
    },
    updateClientInfo: (
      context,
      { name, name2, address, suburb_name, postalcode, mobilenumer }
    ) => {
      context.statement = {
        name,
        name2,
        address,
        suburb_name,
        postalcode,
        mobilenumer
      };
    },
    selectOperatorById: (context, id) => {
      const chosenOp = context.GETALLOPERATORS.filter(
        item => item.taxi_operator_id === id
      )[0];
      const selectedOp = {
        name: chosenOp.firstname + "" + chosenOp.surname,
        name2: undefined,
        address: chosenOp.physicaladdress,
        suburb_name: chosenOp.physical_suburb,
        postalcode: chosenOp.physical_suburb_postalcode,
        mobilenumer: chosenOp.mobilenumber
      };
      context.updateClientInfo(context, selectedOp);
    },
    selectDriverById: (context, id) => {
      const chosenOp = context.GETAllTAXIDRIVERS.filter(
        item => item._taxi_drivers_id === id
      )[0];
      const selectedOp = {
        name: chosenOp._firstname + "" + chosenOp._surname,
        name2: undefined,
        address: chosenOp._physicaladdress,
        suburb_name: chosenOp._physical_suburb,
        postalcode: chosenOp.physical_suburb_postalcode,
        mobilenumer: chosenOp._mobilenumber
      };
      context.updateClientInfo(context, selectedOp);
    },
    selectTaxiById: (context, id) => {
      const chosenOp = context.GETALLTAXIS.filter(
        item => item._taxi_operator_vehicles_id === id
      )[0];
      
      const selectedOp = {
        name: chosenOp._licence_plate,
        name2: chosenOp._taxi_operators_name,
        address: chosenOp.physicaladdress,
        suburb_name: chosenOp.physical_suburb,
        postalcode: chosenOp.physical_suburb_postalcode,
        mobilenumer: chosenOp._taxi_operators_mobilenumber
      };
      context.updateClientInfo(context, selectedOp);
    },
    getInvoiceDetailsId: (context, id) => {
      context.$store
        .dispatch("invoices/getById", {
          router: context.$router,
          id
        })
        .then(() => {})
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    GETTAXISBYMODELID: function(context, id) {
      return context.GETALLTAXIS.filter(item => item._vehiclemodel_id === id);
    },
    getAllTaxiDriversByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getAllByTaxiAssIdNoPic", {
          router: context.$router,
          id
        })
        .then(() => {
          $(".default-selectTaxiDrivers").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false
          });

          $(".default-selectTaxiDrivers")
            .val(null)
            .trigger("change");
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    },
    getAllTaxiByTaxiAssId: (context, id) => {
      context.dataloaded = false;
      context.$store
        .dispatch("OperatorVehicle/getAllByTaxiAssID", {
          router: context.$router,
          id
        })
        .then((data) => {
          context.GETALLTAXIS = data
          
        })
        .catch(error => {
          value.context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {
          $(".default-selectTaxi").select2({
            multiple: false,
            placeholder: "Select A Taxi",
            allowClear: false
          });

          $(".default-selectTaxi")
            .val(null)
            .trigger("change");

            context.dataloaded = true;
        });
    },
    getAllOperatorByTaxiAssId: (context, id) => {
      context.$store
        .dispatch("TO/getOperatorByAccountNo", {
          router: context.$router,
          id
        })
        .then((data) => {
          $(".default-selectOperator").select2({
            multiple: false,
            placeholder: "Select A Operator",
            allowClear: false
          });

          $(".default-selectOperator")
            .val(null)
            .trigger("change");
        })
        .catch(error => {
          context.$Notify.Error(error.data[0].message, "");
        })
        .finally(() => {});
    }
  },
  mounted: function() {
    App.init();

    $('[data-toggle="tooltip"]').tooltip();

    this.$store.dispatch("TA/getLOGOURL", {
      id: this.SELECTEDTAXIASSOCIATION._taxi_associations_id
    }).then((result) => {
      this.context.encodedImage = result;
    }).catch((err) => {
    });

    this.generatetatement(this);
    
  }
};
</script>

<style scoped>
.showduringprintCss {
  display: none;
}
</style>

