<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'FleetManagement'"
      :ActiveMenu="'Upload Bank Statements'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          :pageheaderTitle="'Upload Bank Statements'"
          pageheaderdescription=""
          breadcrumbitem1="Home"
          breadcrumbitem2="Fleet Management"
          breadcrumbitem3="Upload Bank Statements"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

             <!-- begin row -->
             <div class="row">
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-info-circle"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL PAYMENTS</div>
                <div class="stats-number">{{TOTAlTRANSACTIONS | newcurrencywithSymbol}}</div>

                <div class="stats-desc">TOTAL PAYMENT FROM THE FILE</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fa fa-solid fa-times-circle"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL PAYMENT NOT LINKED</div>
                <div class="stats-number">{{TOTALTRANSNOTASSIGNED | newcurrencywithSymbol}}</div>

                <div class="stats-desc">TOTAL PAYMENT NOT LINKED TO OPERATOR</div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-red">
                <i class="fa fa-solid fa-clone"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL DUPLICATES</div>
                <div class="stats-number">{{TOTALDUPLICATES | newcurrencywithSymbol}}</div>

                <div class="stats-desc">TOTAL DUPLICATES PAYMENT</div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
            <!-- begin col-3 -->
          <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-check"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">TOTAL CLEARED TRANSACTIONS</div>
                <div class="stats-number">{{TOTALVERIFIED | newcurrencywithSymbol}}/{{TOTALCLEARED | newcurrencywithSymbol}}</div>

                <div class="stats-desc">TOTAL PAYMENT CLEARED NOT BE UPLOADED</div>
              
              </div>
            </div>
          </div>
          <!-- end col-3 -->
         
           
        </div>

        <!-- end row -->

        

          <!-- begin row -->
          <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">CASH TRANSACTIONS </h4>
              </div>
              <div class="panel-toolbar">
              <div class="btn-group m-r-5">
                <a
                  class="btn btn-white"
                  href="javascript:;"
                  v-on:click="AddModalOpen"
                  :disabled="GETALLTRANSACTIONS.length > 0"
                >
                  <i class="fa fa-plus"></i>
                  Upload File
                </a>
                <button
                          id="btnSubmitUploadedfile"
                          type="button"
                          class="btn btn-sm btn-success width-80"
                          @click="SubmitFileUploaded()"
                          :disabled="$wait.is('upload') || GETALLTRANSACTIONS.length == 0"
                        >
                          <v-wait v-bind:for="'upload'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Submit
                          </v-wait>
                        </button>
                        
              </div>
            </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-thead-sticky table-tfoot-sticky table-tbody-bordered table-px-10px table-py-4px table-sm table-striped text-nowrap mb-0">
              <thead>
                <tr>
                  <th>DATE</th>
                  <th>CLIENT</th>
                  <th>ID NO</th>
                  <th>ARREARS</th>
                  <th>TRANS TYPE</th>
                  <th>DEPOSIT METHOD</th>
                  <th>REFERENCE</th>
                  <th>AMOUNT</th>
                  <th>STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETALLTRANSACTIONS"
                    v-bind:key="index"
                    :class="{
                    'danger': !item.clientid || item.isduplicate,
                    'success': item.clientid && item.isverified && !item.isduplicate,
                    }"
                >
                <td data-toggle="tooltip" data-placement="top" title="Tooltip on top">{{item.transdate | formatToShortDate}}</td>
                <td>
                  <a
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                    v-if="item.clientid && !item.iseditmode"
                  >
                    {{ item.firstname }} {{ item.surname }}
                  </a>

                  <select
                                    v-bind:id="index + '-UpdateTrans'"
                                    class="form-control selectClients"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    v-if="item.iseditmode"
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="(clientitem,index) in GETCLIENTSBYASSID"
                                      :value="index"
                                    >{{clientitem.firstname}} {{clientitem.surname}} - {{clientitem.id_number}}</option>
                  </select>

                  </td>          
                <td>{{item.idnumber}}</td>
                <td>
                  <a
                  v-if="item.clientid"
                    :href="$router.resolve({ name: 'fleetprintstatement', params: { id: item.clientid } }).href"
                    target="_blank"
                    rel="noopener"
                  >
                  <span v-if="item.balance <= 0"
                        class="badge f-s-12 badge-success"
                      >  {{item.balance | newcurrencywithSymbol}}</span>
                      <span v-if="item.balance > 0"
                        class="badge f-s-12 badge-danger"
                      >  {{item.balance | newcurrencywithSymbol}}</span>
                  </a>
                </td>
                <td>
                  <span v-if="item.selectitem && !item.iseditmode"
                        class="badge f-s-12 badge-success"
                      >  {{item.selectitem.name}}</span>
                      <div v-if="item.iseditmode">
                  <select
                                    v-bind:id="index + '-UpdateTransType'"
                                    class="form-control"
                                    data-size="10"
                                    data-live-search="true"
                                    data-parsley-group="step-1"
                                    data-parsley-required="true"
                                    @change="UpdateTransType(context, item, item.selectitem)"
                                    v-model="item.selectitem"
                                    v-select2
                                  >
                                  <option value="">Select</option>
                                    <option
                                      v-bind:key="clientitem.id"
                                      v-for="clientitem in GETTRANSTYPES"
                                      :value="clientitem"
                                    >{{clientitem.name}}</option>
                  </select>
                </div>
                </td>
                <td>{{item.transtype}}</td>
                <td>{{item.reference}}</td>
                <td>{{item.transamount | newcurrencywithSymbol}}</td>
                <td>
                  <span class="badge bg-warning"  v-if="!item.clientid"> CLIENT NOT FOUND  </span>
                    <span class="badge bg-danger" v-if="item.isduplicate"> DUPLICATE TRANSACTION </span>
                    <span class="badge bg-success" v-if="item.clientid && !item.isduplicate"> ALL CLEAR </span>
                </td>
                <td>
                  <button
                    v-if="!item.isverified && item.clientid && !item.isduplicate && item.transtypeid && !item.iseditmode"
                        type="button"
                        class="btn btn-sm btn-warning width-60 m-r-2"
                        @click="item.isverified = true"
                      >
                      Verify
                </button>
                <button
                    v-if="item.isverified && item.clientid && !item.isduplicate && !item.iseditmode && item.transtypeid"
                        type="button"
                        class="btn btn-sm btn-danger width-60 m-r-2"
                        @click="item.isverified = false"
                      >
                      Undo
                </button>
                  <button
                    v-if="!item.iseditmode && !item.isduplicate && !item.isverified"
                        type="button"
                        class="btn btn-sm btn-secondary width-60 m-r-2"
                        @click="EditTrans(context, item,index)"
                      >
                      Edit
                </button>
                <button
                v-if="item.iseditmode && !item.isduplicate"
                        type="button"
                        class="btn btn-sm btn-success width-60 m-r-2"
                        @click="DoneEditTrans(context, item,index)"
                      >
                      Done
                </button>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->
          </div>
        </div>
        <!-- end row -->


          <!-- Add Modal HTML START-->
  <div id="AddModal" class="modal fade">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <form>
                      <div class="modal-header">
                        <h4 class="modal-title">Upload File</h4>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-hidden="true"
                        >&times;</button>
                      </div>
                      <div class="modal-body">
                        <div class="panel panel-primary" data-sortable-id="form-dropzone-1">
                      <div class="panel-heading">
                        <div class="panel-heading-btn"></div>
                        <h4 class="panel-title">Drop Files In Here</h4>
                      </div>
                      <div class="panel-body text-inverse">
                        <DropZoneControl
                          v-show="DROPZONEVISIBLE"
                          :acceptedFiles="acceptedFiles"
                          :ClearData="false"
                          :maxFiles=1
                          v-on:fileadded="fileadded"
                          :SessionID="documentsessionid"
                        ></DropZoneControl>
                      </div>
                    </div>
                      </div>
                      <div class="modal-footer">
                        <input
                          type="button"
                          class="btn btn-default"
                          data-dismiss="modal"
                          value="Cancel"
                        >
                        <button
                          type="button"
                          class="btn btn-sm btn-success width-80 disabled"
                          @click="SubmitFileType()"
                          :disabled="$wait.is('add') || GETALLTRANSACTIONS.length > 0"
                        >
                          <v-wait v-bind:for="'add'">
                            <template slot="waiting">
                              <i class="fas fa-spinner fa-spin"></i>
                            </template>
                            Submit
                          </v-wait>
                        </button>
                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <!-- Add Modal HTML START-->




      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "fleetmanagement"
);

export default {
  name: "UPLOADFUELTRANSACTIONS",
  data: function() {
    return {
      acceptedFiles: "text/csv",
      message: "Some Message",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      rankid: this.$route.params.id,
      rankname: this.$route.params.desc,
      GETALLTRANSACTIONS:[],
      GETALLVEHICLESTRANSACTIONS:[],
      GETROUTESTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETQEUEUEDDETAILS: {},
      GETBOARDQEUEUEDDETAILS: {},
      GETTRIPSDETAILS: {},
      GETMOSTTRIPSTOP5DETAILS: {},
      GETMOSTLEASTTOP5DETAILS: {},
      startDate:null,
      endDate:null,
      GETALLROUTES: {},
      ROUTEID: {},
      context: this,
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      paymentsloaded: false,
      documentsessionid: this.$uuid.v4(),
      GETUPLOADEDFILES: [],
      GETUPLOADEDFILESMATCH: [],
      GETALLDOCUMENTTYPE: [],
      GETCLIENTSBYASSID: [],
      GETTRANSTYPES: [],
      columntype: 0,
      DROPZONEVISIBLE: true,
      COLUMNTYPE: {
        "DATE": "",
        "REGNO": "",
        "FUELTYPE": "",
        "FUELVALUE": "",
        "LITRES": "",
      },
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl
  },
  computed: {
    TOTAlTRANSACTIONS: function () {
      var results = 0.0;
      for (var i = 0; i < this.GETALLTRANSACTIONS.length; i++) {
        results += this.GETALLTRANSACTIONS[i].transamount;
      }
      return results;
    },
    TOTALTRANSNOTASSIGNED: function () {
      var results = 0.0;
      for (var i = 0; i < this.GETALLTRANSACTIONS.length; i++) {
        if (!this.GETALLTRANSACTIONS[i].clientid) {
          results += this.GETALLTRANSACTIONS[i].transamount;
        }
      }
      return results;
    },
    TOTALDUPLICATES: function () {
      var results = 0.0;
      for (var i = 0; i < this.GETALLTRANSACTIONS.length; i++) {
        if (this.GETALLTRANSACTIONS[i].isduplicate) {
          results += this.GETALLTRANSACTIONS[i].transamount;
        }
      }
      return results;
    },
    TOTALCLEARED: function () {
      var results = 0.0;
      for (var i = 0; i < this.GETALLTRANSACTIONS.length; i++) {
        if (!this.GETALLTRANSACTIONS[i].isduplicate && this.GETALLTRANSACTIONS[i].clientid) {
          results += this.GETALLTRANSACTIONS[i].transamount;
        }
      }
      return results;
    },
    TOTALVERIFIED: function () {
      var results = 0.0;
      for (var i = 0; i < this.GETALLTRANSACTIONS.length; i++) {
        if (this.GETALLTRANSACTIONS[i].isverified) {
          results += this.GETALLTRANSACTIONS[i].transamount;
        }
      }
      return results;
    },
    TOTALNEWTRANS: function () {
      return this.TOTAlTRANSACTIONS - this.TOTALDUPLICATES;
    },
  },
  methods: {
    ...mapActions(["getfuelmanageropsdashboardcards", "getuploadfiledata","getoperationstransactions","getprocessuploadfiledata","submitprocessuploadfiledata","getAllClients","getAllTransTypes"]),
    DoneEditTrans: (context, item,index) => {
      $("#" + index + "-UpdateTrans").select2('destroy');
      item.iseditmode = false;
    },
    EditTrans: (context, item,index) => {
      debugger;
      item.iseditmode = true;
     
       setTimeout(() => {
            $("#" + index + "-UpdateTrans").select2({
                multiple: false,
                placeholder: "Select Client",
                allowClear: false,
          });
          $("#" + index + "-UpdateTrans").on("select2:select", function(e) {

            debugger;

            var context = $(document).data("context");

            var item = context.GETALLTRANSACTIONS[index];

            var selectedclient = context.GETCLIENTSBYASSID[parseInt(e.params.data.id)];

            context.UpdateTrans(context, item, selectedclient)
          
        });
          }, 100);
      
    },
    UpdateTrans: (context, item,newitem) => {
      item.clientid = newitem.id;
      item.firstname = newitem.firstname;
      item.surname = newitem.surname;
      item.idnumber = newitem.id_number;
      item.noofvehicles = newitem.numberofvehicles;
      item.balance = newitem.outstanding_balance;
      item.taxiassociationid = newitem.fk_taxiassociation_id;
    },
    UpdateTransType: (context, item,newitem) => {
      item.transtypeid = newitem.id;
    },
    getAllFleetClients: (context, taxiassociationid) => {
      context
        .getAllClients({
          router: context.$router,
          id: taxiassociationid,
        })
        .then((results) => {
          
          context.GETCLIENTSBYASSID = results.data[0].data;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          // setTimeout(() => {
          $(".selectClients").select2({
            multiple: false,
            placeholder: "Select Client",
            allowClear: false,
          });
          // }, 100);
        });

        context
        .getAllTransTypes({
          router: context.$router,
          id: taxiassociationid,
        })
        .then((results) => {
          
          context.GETTRANSTYPES = results.data[0].data;
        })
        .catch((error) => {
          console.log("operator errors:", error);
        })
        .finally(() => {
          // setTimeout(() => {
          $(".selectClients").select2({
            multiple: false,
            placeholder: "Select Client",
            allowClear: false,
          });
          // }, 100);
        });
    },
    AddModalOpen: () => {
      const context = $(document).data("context");
      context.DROPZONEVISIBLE = true;
      $('#AddModal').modal({
      backdrop: 'static',
      keyboard: false
    });
    },
    LOADCARDS:  (context) => {
      
    },
    ClearData: (context) => {
    },
    SubmitFileType: () => {
      const context = $(document).data("context");
      context.$wait.start("add");

      const payload = {
        taxiassociationsid: context.taxiassociationid,
        sessionid: context.documentsessionid,
      }

      context.getprocessuploadfiledata({ router: context.$router, payload })
      .then(parsedResultOP => {

        context.GETALLTRANSACTIONS = parsedResultOP;
        $('[data-toggle="tooltip"]').tooltip();
        context.LOADCARDS(context);
        
      })
      .catch(error => {
       
      }).finally(() => {

        context.$wait.end("add");
        $('#AddModal').modal('hide');
           
           var options = {
                   dom: "lBfrtip",
                   buttons: [
                     { extend: "copy", className: "btn-sm" },
                     { extend: "csv", className: "btn-sm" },
                     { extend: "excel", className: "btn-sm" },
                     { extend: "pdf", className: "btn-sm" },
                     { extend: "print", className: "btn-sm" }
                   ],
                   responsive: false,
                   autoFill: false,
                   colReorder: true,
                   keys: false,
                   rowReorder: false,
                   select: false
                 };

                 if ($(window).width() <= 767) {
                   options.rowReorder = false;
                   options.colReorder = false;
                   options.autoFill = false;
                 }

                
                 if(!context.paymentsloaded){
                   $(".table-invoice").DataTable(options);
                 }

                 context.paymentsloaded = true;

         });

    },
    EditAll: () => {
      const context = $(document).data("context");
      context.GETALLTRANSACTIONS.forEach(item => {
        item.iseditmode = true;
      });

    },
    SubmitFileUploaded: () => {
      const context = $(document).data("context");
      context.$wait.start("upload");

      const payload = {
        taxiassociationsid: context.taxiassociationid,
        transactiondata: JSON.stringify(context.GETALLTRANSACTIONS),
        added_by: context.USERID,
      }

      context.submitprocessuploadfiledata({ router: context.$router, payload })
      .then(parsedResultOP => {

        if (parsedResultOP.status) {
          context.$Notify.Success("Successfully Added!", "");
          context.GETALLTRANSACTIONS = [];
        } else {
          context.$Notify.Error("Error Adding!", "");
        }
        
      })
      .catch(error => {
       
      }).finally(() => {
        context.$wait.end("upload");
      })

    },
    onChangeColumn: ($event, item, name,columntype,index) => {
      const context = $(document).data("context");

      var nametype;
      //DATE
      if(columntype == 1){
        nametype = "DATE"
        debugger
        context.COLUMNTYPE.DATE = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //REGNO
      if(columntype == 2){
        nametype = "REGNO"
        context.COLUMNTYPE.REGNO = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //FUELTYPE
      if(columntype == 3){
        nametype = "FUELTYPE"
        context.COLUMNTYPE.FUELTYPE = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //FUELVALUE
      if(columntype == 4){
        nametype = "FUELVALUE"
        context.COLUMNTYPE.FUELVALUE = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

      //LITRES
      if(columntype == 5){
        nametype = "LITRES"
        context.COLUMNTYPE.LITRES = name;
        context.GETALLDOCUMENTTYPE = context.GETALLDOCUMENTTYPE.filter(user => user.id !== columntype);
        $('#'+index + '-select').hide();
        $('#'+index + '-span').text(nametype);
      }

     

      //console.log(JSON.stringify(context.COLUMNTYPE));
    },
    fileadded: (data) => {
      const context = $(document).data("context");
      // context.GETUPLOADEDFILES = [];
      // context.GETUPLOADEDFILES.push({
      //   filename: data.name,
      //   mimetype: data.type,
      //   fileId: data.fileId,
      // });

      context.SubmitFileType();

    },
    init: function() {},
    Delete: (context,item, id, deletedby) => {
      
      context.$Notify.YesNo(false, { context, item,id, deletedby }).then((value) => {
        if (value) {
          // value.context.$wait.start("delete-" + value.id);

          // var router = value.context.$router;

          //  var payload = {
          //     tripid: value.id,
          //     userid: value.deletedby,
          //   };

          // value.context.markAsDeleted({ router, payload })
          //   .then(() => {
          //     //value.context.GETVEHICLEBYASSID = value.context.GETVEHICLEBYASSID.filter(item => item._taxi_operator_vehicles_id !== value.id);
          //     value.context.$wait.end("delete-" + value.id);
          
          //     value.context.$Notify.Success("Successfully Deleted!", "");
               value.item.date_deleted = value.id;
          //   })
          //   .catch((error) => {
          //     value.context.$wait.end("delete-" + value.id);
          //     value.context.$Notify.Error(error.data[0].message, "");
          //   })
          //   .finally(() => {});
        }
      });
    },
    onChange(context) {

      const router = context.$router;

      var payload = {
          taxiassociationsId: context.USERPROFILE.taxi_associations_id,
          startDate: context.startDate,
          endDate:context.endDate,
        };

    context.getfuelmanageropsdashboardcards({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETDASHBOARDCARDS = parsedResultOP;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      context.getoperationstransactions({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        context.GETALLTRANSACTIONS = parsedResultOP.alltripsdata;
        context.GETALLVEHICLESTRANSACTIONS = parsedResultOP.allvehicles;
        
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally(() => {
           
           var options = {
                   dom: "lBfrtip",
                   buttons: [
                     { extend: "copy", className: "btn-sm" },
                     { extend: "csv", className: "btn-sm" },
                     { extend: "excel", className: "btn-sm" },
                     { extend: "pdf", className: "btn-sm" },
                     { extend: "print", className: "btn-sm" }
                   ],
                   responsive: false,
                   autoFill: false,
                   colReorder: true,
                   keys: false,
                   rowReorder: false,
                   select: false
                 };

                 if ($(window).width() <= 767) {
                   options.rowReorder = false;
                   options.colReorder = false;
                   options.autoFill = false;
                 }

                
                 if(!context.paymentsloaded){
                   $(".table-invoice").DataTable(options);
                 }

                 context.paymentsloaded = true;

         });

    }
  },
  mounted: function() {
    $(document).data("context", this);

    App.init();

    // $(".selectClients").autocomplete({
    //       source: ["ActionScript", "AppleScript", "Asp", "BASIC", "C"]
    //     });
    
   this.getAllFleetClients(this.context, this.taxiassociationid);

    $('[data-toggle="tooltip"]').tooltip();
   
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>