/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-underscore-dangle */
import config from '../../configurations/configbase';
import configbase from '../../configurations/configbasefintech';

export default {
  namespaced: true,
  state: {
    taxi_vehicle: [],
  },
  getters: { // public method to perform Computed
    // Here we will create a getter

    GETALLVEHICLES: state => state.taxi_vehicles,
  },

  mutations: {

  },
  actions: {
    /* Async */
    takepayment({
                      state,
                      rootState,
                      rootGetters,
                      commit,
                      dispatch,
                      getters,
                    }, {
                      router,
                      payload,
                    }) {
                      return new Promise((resolve, reject) => {
                
                        const requestheader = {
                          router,
                          token: rootGetters['auth/TOKEN'],
                          apiName: config.FLEETMANAGEMENPAYMENTSCUSTOM_URL,
                        };
                
                        this._vm.$TAMS_API.add(requestheader,
                          payload).then((parsedResult) => {
                          console.log('Payment made: ', parsedResult);
                          if (parsedResult.status) {
                            console.log('Payment made: ', )
                            commit('LOADPAYMENTRESULTS', parsedResult.data);
                            resolve(parsedResult);
                            // navigateTo(router, parsed_result.data[0]);
                          } else
                            reject(arsedResult.data[0]);
                        }).catch((error) => {
                          // commit('LOGINFAILED', error.data);
                          reject(error);
                        });
                      });
    },
    addBooking({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { 
      AddModel, router,
     }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENT_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllBookingByTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllTransTypes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLTRANSTYPE_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllClients({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLCLIENTS_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllMandates({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLMANDATES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllActiveMandates({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLACTIVEMANDATES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllSuspendedMandates({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTSUSPENDEDMANDATES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllNotAssignedMandates({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTNOTASSIGNEDMANDATES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllCompletedMandates({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTCOMPLETEDMANDATES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllCancelledMandates({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTCANCELLEDMANDATES_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllClientsyTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLCLIENTSBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllClientVehiclessyTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLCLIENTSVEHILCLESBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    submitprocessuploadfiledata({
          state, rootState, rootGetters, commit, dispatch, getters,
        }, { router, payload }) {
          return new Promise((resolve, reject) => {
          const requestheader = {
            router,
            token: rootGetters['auth/TOKEN'],
            apiName: config.FLEETMANAGEMENTSUBMITTEDPROCESSUPLOADED_URL,
          };
      
          this._vm.$TAMS_API.add(requestheader,
            payload
          ).then((parsedResultOP) => {
              resolve(parsedResultOP);
          }).catch((error) => {
            console.log(error);
            commit('LOGINFAILED', error.data);
          });
        });
    },
      UpdateNotAssignedMandate({
        state, rootState, rootGetters, commit, dispatch, getters,
      }, { router, payload }) {
        return new Promise((resolve, reject) => {
        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTUPDATENOTASSIGNEDMANDATE_URL,
        };
    
        this._vm.$TAMS_API.add(requestheader,
          payload
        ).then((parsedResultOP) => {
            resolve(parsedResultOP);
        }).catch((error) => {
          console.log(error);
          commit('LOGINFAILED', error.data);
        });
      });
  },
    getprocessuploadfiledata({
          state, rootState, rootGetters, commit, dispatch, getters,
        }, { router, payload }) {
          return new Promise((resolve, reject) => {
          const requestheader = {
            router,
            token: rootGetters['auth/TOKEN'],
            apiName: config.FLEETMANAGEMENTPROCESSUPLOADEDFILE_URL,
          };
      
          this._vm.$TAMS_API.add(requestheader,
            payload
          ).then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {
              console.log(parsedResultOP);
              resolve(parsedResultOP.data);
            }
          }).catch((error) => {
            console.log(error);
            commit('LOGINFAILED', error.data);
          });
        });
        },
    getuploadfiledata({
          state, rootState, rootGetters, commit, dispatch, getters,
        }, { router, payload }) {
          return new Promise((resolve, reject) => {
          const requestheader = {
            router,
            token: rootGetters['auth/TOKEN'],
            apiName: config.FLEETMANAGEMENTPROCESSUPLOADEDFILE_URL,
          };
      
          this._vm.$TAMS_API.add(requestheader,
            payload
          ).then((parsedResultOP) => {
            console.log(parsedResultOP);
            if (parsedResultOP.status) {
              console.log(parsedResultOP);
              resolve(parsedResultOP.data);
            }
          }).catch((error) => {
            console.log(error);
            commit('LOGINFAILED', error.data);
          });
        });
    },
    getAllClientPayments({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLPAYMENTSBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllClientTransactions({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.LEETMANAGEMENTSTATEMENTOFACCOUNT_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllClientsArrearsyTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLCLIENTSARREARSBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllTransactionsByClientId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router,id }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTALLTRANSBYCLIENTID_URL,
        };

        this._vm.$TAMS_API.getById(requestheader,
          id
          ).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    GetBanks({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.BANKDebitOrders_URL,
        };

        this._vm.$TAMSFIN_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    GetAccountTypes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.ACCOUNTTYPEDebitOrders_URL,
        };

        this._vm.$TAMSFIN_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    CaptureNuPayContract({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, payload) {
      
      const url = configbase.FIN_DEBITORDERS_URL("");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(
              payload
            ),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    CancelNuPayContract({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, payload) {
      
      const url = configbase.FIN_DEBITORDERS_URL("CancelDebitOrder");
        return new Promise((resolve, reject) => {
          fetch(url, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'X-Access-Token':rootGetters['auth/TPAYTOKEN'],
            }),
            body: JSON.stringify(
              payload
            ),
          }).then(response => response.json()).then((parsedResult) => {
            resolve(parsedResult);
          }).catch((error) => {
            reject(error);
          }).finally(() => {

          });
        });
    },
    UpdateContract({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, AddModel) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router:null,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENUPDATECONTRACT_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    getAllInventoryTaxiAssId({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, AddModel) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router:null,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTINVENTORYBYTAXIASSID_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    AddInventory({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, AddModel) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router:null,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTINVENTORYADD_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    GetInventoryTypes({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, { router }) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTGETALLINVENTORYTYPES_URL,
        };

        this._vm.$TAMS_API.getAll(requestheader).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
    UpdateInventory({
      state, rootState, rootGetters, commit, dispatch, getters,
    }, AddModel) {
      return new Promise((resolve, reject) => {

        const requestheader = {
          router:null,
          token: rootGetters['auth/TOKEN'],
          apiName: config.FLEETMANAGEMENTINVENTORYUPDATE_URL,
        };

        this._vm.$TAMS_API.add(requestheader,
          AddModel).then((parsedResult) => {
          if (parsedResult.status) {
            resolve(parsedResult);
          } else {
            reject(parsedResult);
          }
        }).catch((error) => {
          reject(error);
        });
      });
    },
  },
};





// ===
// Private helpers
// ===

function navigateTo(router, _userProfile) {
  if (_userProfile.is_system_admin) {
    router.push('sysadmin_dashboard');
  }
}
