import { render, staticRenderFns } from "./listofvehicles.vue?vue&type=template&id=6fcc3a08&scoped=true&"
import script from "./listofvehicles.vue?vue&type=script&lang=js&"
export * from "./listofvehicles.vue?vue&type=script&lang=js&"
import style0 from "./listofvehicles.vue?vue&type=style&index=0&id=6fcc3a08&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fcc3a08",
  null
  
)

export default component.exports